/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EVMChain,
    EVMChainFromJSON,
    EVMChainFromJSONTyped,
    EVMChainToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddGuildBody
 */
export interface AddGuildBody {
    /**
     * 
     * @type {string}
     * @memberof AddGuildBody
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AddGuildBody
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AddGuildBody
     */
    groupingId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddGuildBody
     */
    games: Array<string>;
    /**
     * 
     * @type {EVMChain}
     * @memberof AddGuildBody
     */
    autoMintChain?: EVMChain;
    /**
     * 
     * @type {string}
     * @memberof AddGuildBody
     */
    autoMintContract?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddGuildBody
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddGuildBody
     */
    hero?: string;
    /**
     * 
     * @type {string}
     * @memberof AddGuildBody
     */
    icon?: string;
}

export function AddGuildBodyFromJSON(json: any): AddGuildBody {
    return AddGuildBodyFromJSONTyped(json, false);
}

export function AddGuildBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddGuildBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'groupingId': json['groupingId'],
        'games': json['games'],
        'autoMintChain': !exists(json, 'autoMintChain') ? undefined : EVMChainFromJSON(json['autoMintChain']),
        'autoMintContract': !exists(json, 'autoMintContract') ? undefined : json['autoMintContract'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'hero': !exists(json, 'hero') ? undefined : json['hero'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
    };
}

export function AddGuildBodyToJSON(value?: AddGuildBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'groupingId': value.groupingId,
        'games': value.games,
        'autoMintChain': EVMChainToJSON(value.autoMintChain),
        'autoMintContract': value.autoMintContract,
        'isActive': value.isActive,
        'hero': value.hero,
        'icon': value.icon,
    };
}


