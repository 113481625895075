/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Challenge,
    ChallengeFromJSON,
    ChallengeFromJSONTyped,
    ChallengeToJSON,
    ChallengeReward,
    ChallengeRewardFromJSON,
    ChallengeRewardFromJSONTyped,
    ChallengeRewardToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChallengeResponse
 */
export interface ChallengeResponse extends Challenge {
}

export function ChallengeResponseFromJSON(json: any): ChallengeResponse {
    return ChallengeResponseFromJSONTyped(json, false);
}

export function ChallengeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChallengeResponse {
    return json;
}

export function ChallengeResponseToJSON(value?: ChallengeResponse | null): any {
    return value;
}


