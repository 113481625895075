import {GetTopGuildLeadersLeaderboardResponse, GuildsApi, Token} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useQuery} from "@tanstack/react-query";

async function fetchTopGuildLeadersLeaderboard(leaderboardTimeParam: string): Promise<GetTopGuildLeadersLeaderboardResponse> {
    return await new GuildsApi(getConfig()).getTopGuildLeadersLeaderboard({leaderboardTimeParam});
}

function useGetTopGuildLeadersLeaderboardQuery(fullToken: Token, leaderboardTimeParam: string, enabled: boolean) {
    return {
        queryKey: [
            "top-guild-leaders-leaderboard",
            leaderboardTimeParam,
        ],
        queryFn: () => fetchTopGuildLeadersLeaderboard(leaderboardTimeParam),
        staleTime: 30 * 60000,
        enabled: !!leaderboardTimeParam && !!fullToken && enabled,
    } as const;
}

export default function useGetTopGuildLeadersLeaderboard(fullToken: Token, leaderboardTimeParam: string, enabled: boolean) {
    return useQuery(useGetTopGuildLeadersLeaderboardQuery(fullToken, leaderboardTimeParam, enabled));
}