import AssetImage from "@/components/AssetImage";
import Skeleton from "@/components/skeletons/Skeleton";
import { useAsset } from "@/hooks/useAsset";
import { ChallengeInfoResponse } from "@devour/client";
import { FC } from "react";
import { isMobile } from "react-device-detect";

interface Props {
    challenge: ChallengeInfoResponse | undefined;
}
export const BrandChallengeCard: FC<Props> = ({challenge}) => {
    const challengeImage = isMobile ? challenge?.backgroundImageSmall : challenge?.backgroundImageLarge;
    const { data } = useAsset((challenge ? challengeImage : "") as any);
    if (!challenge) {
        return <Skeleton height={210} width="100%" />;
    }
    return (
        <div className="brand-challenge_card" style={{backgroundImage: `url(${data?.cdnUrl ?? ""})`}}>
            <div className="brand-challenge_card_shader"></div>
            <div className="brand-challenge_card_content">
                <div className="brand-challenge_card_content_info">
                    <AssetImage assetId={challenge.brandLogo as any} alt="brand-logo" />
                    <h5>{challenge.name}</h5>
                    <span>{challenge.description}</span>
                </div>
            </div>
        </div>
    );
};