export const formatChallengeDateRange = (start: number, end: number) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const formatter = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
    });

    const startFormatted = formatter.format(startDate);
    const endFormatted = formatter.format(endDate);

    const startSuffix = getDaySuffix(startDate.getDate());
    const endSuffix = getDaySuffix(endDate.getDate());

    return `${startFormatted}${startSuffix} - ${endFormatted}${endSuffix}`;
};

function getDaySuffix(day) {
    if (day >= 11 && day <= 13) return "th";
    switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
}
