/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection maps users to guilds.
 * @export
 * @interface GuildMembership
 */
export interface GuildMembership {
    /**
     * 
     * @type {string}
     * @memberof GuildMembership
     */
    guild: string;
    /**
     * 
     * @type {string}
     * @memberof GuildMembership
     */
    user: string;
    /**
     * 
     * @type {number}
     * @memberof GuildMembership
     */
    joinedAt: number;
    /**
     * 
     * @type {number}
     * @memberof GuildMembership
     */
    leftAt?: number;
    /**
     * 
     * @type {number}
     * @memberof GuildMembership
     */
    pointsEarned?: number;
    /**
     * 
     * @type {number}
     * @memberof GuildMembership
     */
    monthlyPointsEarned?: number;
    /**
     * 
     * @type {string}
     * @memberof GuildMembership
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GuildMembership
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof GuildMembership
     */
    updatedAt: number;
}

export function GuildMembershipFromJSON(json: any): GuildMembership {
    return GuildMembershipFromJSONTyped(json, false);
}

export function GuildMembershipFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuildMembership {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guild': json['guild'],
        'user': json['user'],
        'joinedAt': json['joinedAt'],
        'leftAt': !exists(json, 'leftAt') ? undefined : json['leftAt'],
        'pointsEarned': !exists(json, 'pointsEarned') ? undefined : json['pointsEarned'],
        'monthlyPointsEarned': !exists(json, 'monthlyPointsEarned') ? undefined : json['monthlyPointsEarned'],
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function GuildMembershipToJSON(value?: GuildMembership | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guild': value.guild,
        'user': value.user,
        'joinedAt': value.joinedAt,
        'leftAt': value.leftAt,
        'pointsEarned': value.pointsEarned,
        'monthlyPointsEarned': value.monthlyPointsEarned,
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}


