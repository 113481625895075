/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TopGuildLeaderboard
 */
export interface TopGuildLeaderboard {
    /**
     * 
     * @type {string}
     * @memberof TopGuildLeaderboard
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof TopGuildLeaderboard
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof TopGuildLeaderboard
     */
    totalPoints: number;
    /**
     * 
     * @type {number}
     * @memberof TopGuildLeaderboard
     */
    rank?: number;
}

export function TopGuildLeaderboardFromJSON(json: any): TopGuildLeaderboard {
    return TopGuildLeaderboardFromJSONTyped(json, false);
}

export function TopGuildLeaderboardFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopGuildLeaderboard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'totalPoints': json['totalPoints'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
    };
}

export function TopGuildLeaderboardToJSON(value?: TopGuildLeaderboard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'icon': value.icon,
        'name': value.name,
        'totalPoints': value.totalPoints,
        'rank': value.rank,
    };
}


