import {GetOverwolfXpSubjectEnum, OverwolfApi, OWGame} from "@devour/client";
import {ReactElement, ReactNode, useCallback, useEffect, useState} from "react";
import {isMobile, isTablet} from "react-device-detect";
import getConfig from "@/utils/getConfig";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {addError} from "@/redux/meta/metaActions";
import FrameButton from "@/components/buttons/FrameButton";
import classNames from "classnames";
import GoVipOWDualRewardModal from "@/components/goVip/GoVipOWDualRewardModal";
import useGetUserOverwolfXP from "@/hooks/useGetUserOverwolfXP";
import AssetImage from "@/components/AssetImage";
import Tooltip from "@/components/Tooltip";
import {BsFillQuestionCircleFill} from "react-icons/bs";
import GuildsInfoDrawer from "@/components/guilds/GuildsInfoDrawer";

export function GameCard({game, getGameButton, getGuildButton, onClick}: { game: OWGame, getGameButton: (game: OWGame) => ReactNode, getGuildButton: (game: OWGame) => void, onClick?: () => void }): ReactElement {
    return (
        <div className="go-vip-overwolf_dual-rewards_game" onClick={onClick}>
            <div
                className={classNames("go-vip-overwolf_dual-rewards_game_image", {
                    "go-vip-overwolf_dual-rewards_game_image_black-and-white-filter": !game.isEventSupportReady,
                    "go-vip-overwolf_dual-rewards_game_image_no-thumbnail": !game.thumbnailImage,
                })}
            >
                {game.thumbnailImage && <AssetImage assetId={game.thumbnailImage} alt={game.name}/>}
            </div>
            <div className="go-vip-overwolf_dual-rewards_game_button">
                {getGameButton(game)}
                {import.meta.env.VITE_ENVIRONMENT?.includes("local") && <FrameButton
                    size="normal"
                    color="purple"
                    onClick={() => getGuildButton(game)}
                >
                    Guild Stuff
                </FrameButton>}
            </div>
        </div>
    );
}

interface Props {
    isOWDiscoverPage?: boolean;
}

export default function GoVipOWDualRewardingGames(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dispatch = useDispatch();
    const isOnMobile = isMobile && !isTablet;
    const [games, setGames] = useState<Array<OWGame>>([]);
    const [showDualRewardModal, setShowDualRewardModal] = useState(false);
    const [showGuildPanel, setShowGuildPanelModal] = useState(false);
    const {data: rewardsData} = useGetUserOverwolfXP(fullToken, GetOverwolfXpSubjectEnum.EVENTREWARD);
    const [game, setGame] = useState(null);
    const supportedGames = games?.filter(game => game.isEventSupportReady);

    useEffect(() => {
        void fetchGames();
    }, [fullToken?.id]); // Refetch games when user logs in/out

    async function fetchGames() {
        try {
            const gamesData = await new OverwolfApi(getConfig(fullToken)).getOverwolfDualGames();
            setGames(gamesData);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    function gameButtonClickHandler(game: OWGame) {
        setShowDualRewardModal((prev) => !prev);
        setGame(game);
    }

    function getGuildButton(game: OWGame) {
        setShowGuildPanelModal(true);
        setGame(game);
    }

    const getGameButton = useCallback((game: OWGame): ReactNode => {
        if (!game.isEventSupportReady) {
            return (
                /*
                 * <FrameButton
                 *     size="narrow"
                 *     className="go-vip-overwolf_dual-rewards_game_coming-soon"
                 * >
                 *     Coming Soon
                 * </FrameButton>
                 */
                <></>
            );
        }

        if (game.hasUserStats) {
            return (
                <FrameButton
                    size="narrow"
                    className="go-vip-overwolf_dual-rewards_game_track-rewards"
                    onClick={() => gameButtonClickHandler(game)}
                >
                    Track Quests
                </FrameButton>
            );
        }

        return (
            <FrameButton
                size="narrow"
                color="purple"
                onClick={() => gameButtonClickHandler(game)}
            >
                View Quests
            </FrameButton>
        );
    }, []);

    if (!games.length) {
        return null;
    }

    const getHeaderContent = (isOWDiscoverPage: boolean) => {
        // Content for Discover Page
        if (isOWDiscoverPage) {
            return {
                title:
                    <>
                        Play Games with Special Quests
                        <Tooltip
                            className="overwolf-govip_rewards_header_tooltip"
                            outsideIcon={BsFillQuestionCircleFill}
                            containerElementClassname="overwolf-govip_rewards"
                        >
                            <div className="overwolf-govip_rewards_header_tooltip_content">
                            The games listed below feature event-based quests, where our team has created fun and challenging objectives for you to complete and claim rewards!
                            </div>
                        </Tooltip>
                    </>,
                description: "",
            };
        }

        // Default content for goVIP page
        return {
            title:
                <>
                    Dual-Rewarding Games
                    <span>{games.filter(game => game.isEventSupportReady).length}</span>
                </>,
            description: `Complete special quests ${!isOnMobile ? "based on games you play" : ""} to earn XP`,
        };
    };

    const headerContent = getHeaderContent(!!props.isOWDiscoverPage);

    return (
        <div className="go-vip-overwolf_rewards">
            <div className="go-vip-overwolf_rewards_header">
                <div className="go-vip-overwolf_rewards_header_text">
                    <h3 className={props.isOWDiscoverPage ? "overwolf-discover-page_coming-soon" : "go-vip-overwolf_heading"}>
                        {headerContent.title}
                    </h3>

                    <div className="go-vip-overwolf_rewards_header_description">
                        {headerContent.description}
                    </div>
                </div>
            </div>

            <div className="go-vip-overwolf_dual-rewards">
                {(props.isOWDiscoverPage ? supportedGames : games).map(game =>
                    <GameCard
                        key={game.id}
                        game={game}
                        getGameButton={getGameButton}
                        getGuildButton={getGuildButton}
                        onClick={() => gameButtonClickHandler(game)}
                    />)}

                <GuildsInfoDrawer
                    show={showGuildPanel}
                    toggle={() => setShowGuildPanelModal((prev) => !prev)}
                    game={game}
                />
                <GoVipOWDualRewardModal
                    isOpen={showDualRewardModal}
                    questOnly
                    onClose={() => setShowDualRewardModal((prev) => !prev)}
                    rewards={rewardsData?.transactions ?? []}
                    game={game}
                />
            </div>
        </div>
    );
}
