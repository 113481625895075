import React, {CSSProperties, Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState} from "react";
import {OverwolfApi, OWReward, OWRewardMilestone, OWRewardType, OWUserStat, QuestReset} from "@devour/client";
import classNames from "classnames";
import { HiOutlineTicket } from "react-icons/hi";
import CircularProgressIndicator from "../CircularProgress";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import FrameButton from "../buttons/FrameButton";
import {MdCheck} from "react-icons/md";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import getConfig from "@/utils/getConfig";
import useGetOverwolfUserStats from "@/hooks/useGetOverwolfUserStats";
import Toast from "@/components/Toast";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useGetUserLevel} from "@/hooks/useGetUserLevel";
import {store} from "@/redux";
import {updateAccountLevel, updateCurrentUser} from "@/redux/meta/metaActions";
import {BsTriangleFill} from "react-icons/bs";
import AnimateHeight from "react-animate-height";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import Spacer from "@/components/Spacer";
import {findLastIndex, set} from "lodash";
import {useGetOverwolfSummary} from "@/hooks/useGetOverwolfSummary";
import {getTimeUntilQuestReset, nextResetTime, ResetTimeUnit} from "@/utils/devourPlay/getTimeUntilQuestReset";
import {FaRegClock} from "react-icons/fa6";
import useWindowSize from "@/hooks/useWindowSize";
import {useQueryClient} from "@tanstack/react-query";
import { NftPromoModal } from "../modals/NftPromoModal";

interface Props {
    quest: OWReward;
    userStat?: OWUserStat;
    className?: string;
    alwaysMobile?: boolean;
    gameId: string;
    isClaimingInProgress: boolean;
    setIsClaimingInProgress: Dispatch<SetStateAction<boolean>>;
    forceModalDesign?: boolean;
}

const testResetTime = {
    unit: ResetTimeUnit.DAY,
    value: 3,
    str: "3 days",
};

const getClaimedRewardsString = (rewards: Array<{ rewardType: OWRewardType, amount: number }>) => {
    const formattedRewards = rewards.map(reward => `${reward.amount} ${reward.rewardType}`);
    if (formattedRewards.length === 0) {
        return "";
    }

    if (formattedRewards.length === 1) {
        // Single item, return as is
        return `${formattedRewards[0]} successfully claimed!`;
    }

    if (formattedRewards.length === 2) {
        // Two items, join with "and"
        return `${formattedRewards.join(" and ")} successfully claimed!`;
    }

    /*
     * More than two items:
     * Join all but the last two items with commas, then add "and" before the last item.
     */
    const allButLast = formattedRewards.slice(0, -1);
    const last = formattedRewards[formattedRewards.length - 1];
    return `${allButLast.join(", ")}, and ${last} successfully claimed!`;
};

const QuestRewardAmount = (props: { amount: number, rewardType: OWRewardType, forceLighterBg?: boolean }) => {
    const className = props.forceLighterBg ? "go-vip-ow_quest-reward-card_info_content_points_toast-lighter" : "go-vip-ow_quest-reward-card_info_content_points_toast";
    switch (props.rewardType) {
        case OWRewardType.XP:
            return (
                <div className={className}>
                    <span
                        className="go-vip-ow_quest-reward-card_info_content_points_toast_text">{props.amount} XP
                    </span>
                </div>
            );
        case OWRewardType.FUEL:
            return (
                <div className={className}>
                    <img className="go-vip-ow_quest-reward-card_info_content_points_fuel-icon"
                        src={`${import.meta.env.VITE_CDN_URL}/images/FUEL.webp`}
                        alt="DevourGO Logo"
                    />
                    <span className="go-vip-ow_quest-reward-card_info_content_points_toast_text">{props.amount}</span>
                </div>
            );
        case OWRewardType.NFT:
            return <div className={className}>
                <HiOutlineTicket style={{width: "16px",
                    marginRight: ".48rem"}}/>
                <span className="go-vip-ow_quest-reward-card_info_content_points_toast_text">Promo</span>
            </div>;
        default: return null;
    }
};

const getSortedQuestRewards = (rewards: Array<{ rewardType: OWRewardType, amount: number }>) => {
    const order = [OWRewardType.XP, OWRewardType.FUEL];
    return [...rewards].sort((a, b) => order.indexOf(a.rewardType) - order.indexOf(b.rewardType));
};

const MOBILE_BREAKPOINT = 576;

export default function GoVipOWQuestRewardCard(props: Props) {
    const {quest, userStat, forceModalDesign = false} = props;
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [claimingToast, setClaimingToast] = useState<{ message: string, success: boolean }>(undefined);
    const [isQuestCollapsed, setIsQuestCollapsed] = useState<boolean>(true);
    const {refetch: refetchUserStats} = useGetOverwolfUserStats(fullToken, props.gameId, false);
    const {refetch: refetchUserProfile} = useGetUserProfile(fullToken, true);
    const {refetch: refetchSummary} = useGetOverwolfSummary();
    const {refetch: refetchUserLevel} = useGetUserLevel(fullToken, store.getState().metaStore.currentUser?.user.id, true);
    const [promoModalData, setPromoModalData] = useState<{ nftUri: string, promoDescriptions: Array<string>, nftMintDate: string } | undefined>();
    const [isPromoModalOpen, setIsPromoModalOpen] = useState<boolean>(false);

    const isOnMobile = isMobile && !isTablet || props.alwaysMobile;
    const {isOnOverwolf} = useOverwolfInterop();
    const numOfMilestones = quest.milestones?.length || 0;
    const userProgress = userStat?.progress || 0;
    // For Monthly Reset Tag
    const [nextResetTime, setNextResetTime] = useState<nextResetTime | null>(null);
    const FIVE_MINUTES_MS = 5 * 60 * 1000;
    const queryClient = useQueryClient();

    const windowWidth = useWindowSize()[0];

    const showResetWarningColor = () => {
        // Show red reset tag if less than 3 days left
        if (nextResetTime?.unit === ResetTimeUnit.DAY && nextResetTime.value > 3) {
            return false;
        }
        return true;

    };

    const lastMilestoneCompletedIndex = useMemo(
        () => findLastIndex(quest.milestones ?? [], m => m.target <= userProgress),
        [quest.id, userProgress],
    );

    const completedDate: string = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
    }).format(userStat?.updatedAt);

    useEffect(() => {
        if (quest.questReset && quest.questReset !== QuestReset.NONE) {
            setNextResetTime(getTimeUntilQuestReset(quest.questReset));

            // Set reset time every 5 mins
            const intervalId = setInterval(() => {
                setNextResetTime(getTimeUntilQuestReset(quest.questReset));
            }, FIVE_MINUTES_MS);

            return () => clearInterval(intervalId);
        }
    }, [quest.questReset]);

    /**
     * Checks if a user has any unclaimed milestone rewards based on their current progress
     * @param userStat
     * @param quest
     * @returns boolean indicating if there are any unclaimed rewards
     */
    function hasUnclaimedRewards(
        userStat: OWUserStat,
        quest: OWReward,
    ): boolean {
        // Get all milestones that should be claimable based on current progress
        const claimableMilestones = quest.milestones.filter(milestone => userStat.progress >= milestone.target);

        // Get all milestone targets that have been claimed
        const claimedTargets = new Set(userStat.claimLogs.map(log => log.target));

        // Check if there are any claimable milestones that haven't been claimed yet
        return claimableMilestones.some(milestone => !claimedTargets.has(milestone.target));
    }

    async function claimOverwolfReward(target?: number, rewardType?: any) {
        props.setIsClaimingInProgress(true);
        const isClaimingPromo = rewardType === OWRewardType.NFT || (quest as OWReward).questRewards.find(reward => reward.rewardType === OWRewardType.NFT) && !rewardType;
        if (isClaimingPromo) {
            // opens modal to display loading screen
            setIsPromoModalOpen(true);
        }
        try {
            const res: { rewards: Array<{ rewardType: OWRewardType, amount: number, promoDescriptions: Array<string>, nftUri?: string, nftMintDate?: string, }> } = await new OverwolfApi(getConfig(fullToken)).claimOverwolfReward({
                rewardId: quest.id,
                claimedTarget: numOfMilestones > 0 ? target : undefined,
                rewardType: numOfMilestones > 0 ? rewardType : undefined,
                gameId: props.gameId,
            });
            await refetchUserStats();
            await refetchSummary();
            const rewardsWithoutPromo = res.rewards.filter(reward => reward.rewardType !== OWRewardType.NFT);
            const rewardWithPromo = res.rewards.find(reward => reward.rewardType === OWRewardType.NFT);
            if (rewardsWithoutPromo.length > 0) {
                setClaimingToast({
                    message: getClaimedRewardsString(rewardsWithoutPromo),
                    success: true,
                });
            }
            if (rewardWithPromo) {
                setPromoModalData({
                    nftUri: rewardWithPromo.nftUri,
                    promoDescriptions: rewardWithPromo.promoDescriptions,
                    nftMintDate: rewardWithPromo.nftMintDate,
                });
            }
            queryClient.invalidateQueries({ queryKey: ["transactions-paginated"]});
            void updateUserLevel();
        } catch (e) {
            setClaimingToast({
                message: "There has been a problem claiming your reward. Please try again later!",
                success: false,
            });
            setIsPromoModalOpen(false);
        } finally {
            props.setIsClaimingInProgress(false);
        }
    }

    async function updateUserLevel() {
        const [
            updatedUserProfile,
            updatedUserLevel,
        ] = await Promise.all([refetchUserProfile(), refetchUserLevel()]);
        if (updatedUserProfile.data) {
            dispatch(updateCurrentUser(updatedUserProfile.data));
        }

        if (updatedUserLevel.data) {
            dispatch(updateAccountLevel(updatedUserLevel.data));
        }
    }

    const getCurrentProgress = () => {
        if (!props.userStat) {
            return 0;
        }
        if (props.userStat.progress < props.quest.target) {
            return props.userStat.progress;
        }
        return quest.target;
    };

    const renderClaimButton = (target: number, isClaimed?: boolean, rewardType?: OWRewardType) => {
        if (!fullToken || !userStat || userStat.progress < target) {
            return null;
        }

        if (userStat?.isClaimed || isClaimed) {
            return (
                <FrameButton
                    size="narrow"
                    className={classNames(
                        "go-vip-ow_quest-reward-card_claimed",
                        {
                            "desktop-claimed-btn": windowWidth > MOBILE_BREAKPOINT && !forceModalDesign,
                        },
                        {
                            "mobile-claimed-btn": windowWidth <= MOBILE_BREAKPOINT || forceModalDesign,
                        },
                    )}
                >
                    Claimed on {completedDate} {(isOnOverwolf || isDesktop) && <MdCheck/>}
                </FrameButton>
            );
        }

        return (
            <FrameButton
                size="narrow"
                onClick={() => claimOverwolfReward(target, rewardType)}
                color="devourplay-gradient"
                className={classNames(
                    "go-vip-ow_quest-reward-card_claim-btn",
                    {
                        // Mobile claim button should take up full width 100% (not on desktop)
                        "mobile-claim-btn": windowWidth <= MOBILE_BREAKPOINT || forceModalDesign,
                    },
                )}
                forwardProps={{disabled: props.isClaimingInProgress}}
            >
                Claim
            </FrameButton>
        );
    };

    const getCircularProgressGlow = () => {
        return props.userStat && props.userStat.progress >= props.quest.target && !props.userStat.isClaimed;
    };

    const renderResetTag = (top: boolean = false) => {
        if (!nextResetTime || quest?.questReset === QuestReset.NONE || !userStat || userStat.progress === 0) return null;
        const hasUnclaimedXP = hasUnclaimedRewards(userStat, quest);

        return <div className={classNames(
            "go-vip-ow_quest-reward-card_reset-tag",
            {
                "top": top,
                "urgent": showResetWarningColor(),
            },
        )}>
            <FaRegClock/> Resets
            in {nextResetTime.str} {hasUnclaimedXP && showResetWarningColor() && "- Claim your XP!"}
        </div>;
    };

    function renderIcon(): ReactElement {
        if (numOfMilestones) {
            return <BsTriangleFill
                className={classNames(
                    "go-vip-ow_quest-reward-card_info_icon",
                    "go-vip-ow_quest_milestones_collapse-icon", {
                        "go-vip-ow_quest_milestones_collapse-icon_flipped": !isQuestCollapsed,
                    },
                )}
                onClick={() => setIsQuestCollapsed(!isQuestCollapsed)}
            />;
        }
    }

    function getToastStyle(): CSSProperties {
        if (isOnMobile && isOnOverwolf) {
            return {
                maxWidth: "100vw",
                width: "100vw",
                borderRadius: "0",
                marginBottom: "0",
                marginLeft: "0",
            };
        }

        return {
            marginBottom: "0",
        };
    }

    return (
        <>
            <Toast
                message={claimingToast?.message}
                isOpen={!!claimingToast}
                onDismiss={() => setClaimingToast(undefined)}
                removeMarginAdjustment={true}
                className={classNames(
                    "overwolf-govip_rewards_claim",
                    {"overwolf-govip_rewards_claim_in-game": isOnMobile && isOnOverwolf},
                )}
                forceDarkMode={true}
                variant={claimingToast?.success ? "success" : "error"}
                style={isOnOverwolf && getToastStyle()}
            />
            <NftPromoModal
                loading={!promoModalData}
                dateMinted={new Date(promoModalData?.nftMintDate)}
                isOpen={isPromoModalOpen}
                onClose={() => setIsPromoModalOpen(false)}
                image={promoModalData?.nftUri}
                description={
                    promoModalData?.promoDescriptions.length === 1 ? promoModalData?.promoDescriptions[0] : promoModalData?.promoDescriptions.map((desc) => `- ${desc}`).join("\n")
                }
            />

            <div
                className={classNames("go-vip-ow_quest-reward-card", {
                    "disabled": !fullToken,
                    "bottom-spacing": !isQuestCollapsed,
                })}>
                <div>
                    {(!isOnOverwolf || isOnMobile) &&
                        renderResetTag(true)
                    }
                </div>

                <div className="go-vip-ow_quest-reward-card_main">
                    <div style={{width: "100%"}}>
                        <div className="go-vip-ow_quest-reward-card_info">
                            <CircularProgressIndicator
                                showGlow={getCircularProgressGlow()}
                                total={props.quest.target}
                                current={getCurrentProgress()}
                            />
                            <div className="go-vip-ow_quest-reward-card_info_content">
                                <strong className="go-vip-ow_quest-reward-card_info_content_title">
                                    {props.quest.name}
                                    {isOnOverwolf && !isOnMobile &&
                                        renderResetTag()
                                    }
                                </strong>
                                <p className="go-vip-ow_quest-reward-card_info_content_description">
                                    {props.quest.description}
                                </p>

                                {windowWidth > MOBILE_BREAKPOINT && !forceModalDesign &&
                                    <div className="go-vip-ow_quest-reward-card_info_content_points">
                                        Rewards
                                        {getSortedQuestRewards(props.quest.questRewards).map((questReward) => {
                                            return <QuestRewardAmount amount={questReward.amount}
                                                rewardType={questReward.rewardType}/>;
                                        })}
                                    </div>
                                }
                            </div>
                            {renderIcon()}
                        </div>
                        {(windowWidth <= MOBILE_BREAKPOINT || forceModalDesign) &&
                            <div className="go-vip-ow_quest-reward-card_info_content_points" style={{
                                marginTop: "0.6rem",
                                fontSize: "0.72rem",
                            }}>
                                Rewards
                                {getSortedQuestRewards(props.quest.questRewards).map((questReward) => {
                                    return <QuestRewardAmount amount={questReward.amount}
                                        rewardType={questReward.rewardType}/>;
                                })}
                            </div>
                        }
                    </div>

                    {fullToken && !numOfMilestones && <div className="go-vip-ow_quest-reward-card_action">
                        {renderClaimButton(quest.target, props.userStat?.isClaimed)}
                    </div>}
                </div>

                {numOfMilestones > 0 &&
                    <AnimateHeight
                        duration={300}
                        height={isQuestCollapsed ? 0 : "auto"}
                    >
                        <div className="go-vip-ow_quest_milestones">
                            <div className="go-vip-ow_quest_milestones_progress">
                                {Array.from({length: numOfMilestones}, (_, i) =>
                                    <div
                                        className={classNames("go-vip-ow_quest_milestones_progress_marker", {
                                            "completed": userStat?.progress >= quest.milestones[i]?.target,
                                        })}
                                        key={`${quest.id}-marker-${i}`}
                                    />)}
                                <div
                                    className="go-vip-ow_quest_milestones_progress_bar"
                                    style={{height: `${lastMilestoneCompletedIndex / (numOfMilestones - 1) * 100}%`}}
                                />
                            </div>
                            <div className="go-vip-ow_quest_milestones_list">
                                {quest.milestones.map((milestone: OWRewardMilestone, index) =>
                                    <React.Fragment key={`${quest.id}-${milestone.target}`}>
                                        {(windowWidth <= MOBILE_BREAKPOINT || forceModalDesign) &&
                                            <div
                                                key={`${quest.id}-${milestone.target}`}
                                                className={`go-vip-ow_quest_milestones_milestone ${quest.id}-${milestone.target}`}
                                            >
                                                <div className="go-vip-ow_quest_milestones_milestone_description-row">
                                                    <div
                                                        className="go-vip-ow_quest_milestones_milestone_description-row_name">{milestone.name}</div>
                                                    <div
                                                        className="go-vip-ow_quest_milestones_milestone_description-row_points">
                                                        <QuestRewardAmount amount={milestone.amount}
                                                            rewardType={milestone.rewardType}
                                                            forceLighterBg/>
                                                    </div>
                                                </div>
                                                <div className="go-vip-ow_quest_milestones_milestone_claim-button">
                                                    {renderClaimButton(milestone.target, !!userStat?.claimLogs?.find(log => log.target === milestone.target), milestone.rewardType)}
                                                </div>
                                            </div>
                                        }
                                        {windowWidth > MOBILE_BREAKPOINT && !forceModalDesign &&
                                            <div
                                                key={`${quest.id}-${milestone.target}`}
                                                className={`go-vip-ow_quest_milestones_milestone ${quest.id}-${milestone.target}`}
                                                style={{display: "flex"}}
                                            >
                                                <div
                                                    className="go-vip-ow_quest_milestones_milestone_description-column">
                                                    <div
                                                        className="go-vip-ow_quest_milestones_milestone_description-column_name">{milestone.name}</div>
                                                    <div
                                                        className="go-vip-ow_quest_milestones_milestone_description-column_points">
                                                        <QuestRewardAmount amount={milestone.amount}
                                                            rewardType={milestone.rewardType}
                                                            forceLighterBg/>
                                                    </div>
                                                </div>
                                                <div
                                                    className="go-vip-ow_quest_milestones_milestone_claim-button"
                                                >
                                                    {renderClaimButton(milestone.target, !!userStat?.claimLogs?.find(log => log.target === milestone.target), milestone.rewardType)}
                                                </div>
                                            </div>
                                        }
                                        {index !== numOfMilestones - 1 &&
                                            <div className="go-vip-ow_quest_milestones_divider"/>}
                                    </React.Fragment>)}
                            </div>
                        </div>
                    </AnimateHeight>
                }
            </div>
        </>
    );
}
