import {ButtonHTMLAttributes, ReactElement, useState} from "react";
import {getDateInEst} from "@/utils/getDateInEst";
import {TopGuildLeaderboard, TopGuildLeaderLeaderboard} from "@devour/client";
import useGetLeaderboardDateRange from "@/hooks/useGetLeaderboardDateRange";
import {IStore} from "@/redux/defaultStore";
import {useSelector} from "react-redux";
import {LeaderboardView, ViewMode} from "@/components/goFrens/GoFrensTopLeaderboard";
import {isDesktop, isTablet} from "react-device-detect";
import {FaRankingStar} from "react-icons/fa6";
import FrameButton from "@/components/buttons/FrameButton";
import useThemePreference from "@/hooks/useThemePreference";
import FrameOneSelect from "@/components/inputs/FrameOneSelect";
import FrameOneSwitchInput from "@/components/inputs/FrameOneSwitchInput";
import GoFrensTopLeaderboardSkeleton from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardSkeleton";
import GoFrensTopLeaderboardEmptyState from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardEmptyState";
import GoFrensTopLeaderboardCommunityTableHeader
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardCommunityTableHeader";
import GoFrensLeaderboardRow, {LeaderboardRowColor} from "@/components/goFrens/GoFrensLeaderboardRow";
import useGetTopGuildsLeaderboard from "@/hooks/useGetTopGuildsLeaderboard";
import useGetTopGuildLeadersLeaderboard from "@/hooks/useGetTopGuildLeadersLeaderboard";
import GoFrensTopLeaderboardCommunityLeadersEmptyState
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardCommunityLeadersEmptyState";
import GuildsTopLeaderboardModal from "@/pages/guilds/GuildsTopLeaderboardModal";

export enum GuildLeaderboardView {
    GUILDS,
    LEADERS,
}

const MAX_PER_TABLE = 5;

export function getBgColor(index: number, isDarkMode: boolean): LeaderboardRowColor {
    if (!isDarkMode) {
        return index % 2 === 0
            ? "light-gray"
            : "white";
    }

    return index % 2 === 0
        ? "DM-light-gray"
        : "DM-white";
}

export function leaderboardViewModeButtonContent(viewMode: ViewMode, toggleViewMode: () => void, darkMode: boolean, showFullText: boolean): ReactElement {
    const shortText = viewMode === ViewMode.MONTHLY ? "Monthly" : "All-Time";
    const fullText = viewMode === ViewMode.MONTHLY ? "Monthly Leaderboard" : "All-Time Leaderboard";
    return <FrameButton
        <ButtonHTMLAttributes<HTMLButtonElement>>
        className="gofrens-top-leaderboard_view-mode-button"
        color={darkMode ? "white-drop-shadow" : "gray"}
        size="pill"
        onClick={toggleViewMode}
        rightIcon={FaRankingStar}
    >
        <div className="gofrens-top-leaderboard_view-mode">
            {showFullText ? fullText : shortText}
        </div>
    </FrameButton>;
}

export default function GuildsTopLeaderboard(): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const now = getDateInEst();
    const currMonth = now.getMonth() + 1;
    const currYear = now.getFullYear();
    const { isOnDarkMode } = useThemePreference();

    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.MONTHLY);
    const [leaderboardView, setLeaderboardView] = useState<GuildLeaderboardView>(GuildLeaderboardView.GUILDS);

    const [dateValue, setDateValue] = useState<string>(`${currMonth},${currYear}`);
    const [showLeaderboardModal, setShowLeaderboardModal] = useState<boolean>(false);

    const {data: dateRange} = useGetLeaderboardDateRange(fullToken, "guild");
    const {data: guildsData, isFetching: isGuildsDataFetching} = useGetTopGuildsLeaderboard(fullToken, `${dateValue},${viewMode}`, leaderboardView === GuildLeaderboardView.GUILDS);
    const {data: leadersData, isFetching: isLeadersDataFetching} = useGetTopGuildLeadersLeaderboard(fullToken, `${dateValue},${viewMode}`, leaderboardView === GuildLeaderboardView.LEADERS);

    function toggleLeaderboardView(): void {
        setLeaderboardView(leaderboardView === GuildLeaderboardView.GUILDS ? GuildLeaderboardView.LEADERS : GuildLeaderboardView.GUILDS);
    }

    function toggleViewMode(): void {
        setViewMode(viewMode === ViewMode.MONTHLY ? ViewMode.CUMULATIVE : ViewMode.MONTHLY);
    }

    function renderDateDropdown(): ReactElement {
        return (
            <FrameOneSelect
                className="gofrens-top-leaderboard_top-inputs_dropdown-container_select guilds-top-leaderboard_header_btns_main_dropdown"
                disabled={!dateRange || leaderboardView === GuildLeaderboardView.GUILDS ? !guildsData : !leadersData}
                value={dateValue}
                onChange={(e) => setDateValue(e.target.value)}
            >
                <option value={`${currMonth},${currYear}`}>Current Month</option>
                {dateRange?.dates.map(date => <option value={date.dateParam} key={date.dateParam}>{date.dateValue}</option>)}
            </FrameOneSelect>
        );
    }

    function renderLeaderboards(): ReactElement {
        if (!guildsData || isGuildsDataFetching) {
            return <GoFrensTopLeaderboardSkeleton/>;
        } if (guildsData.guilds.length === 0) {
            return <GoFrensTopLeaderboardEmptyState
                isOnGuildsPage={true}
                headerText={"There are no top guilds atm..."}
                contentText={"We are waiting for guilds to be listed on this leaderboard. Start by joining a guild and earning XP to see your guild here!"}
            />;
        }

        const renderRightTable = guildsData.guilds.length > MAX_PER_TABLE;
        return (
            <>
                <div className="gofrens-top-leaderboard_boards_right">
                    <GoFrensTopLeaderboardCommunityTableHeader leaderboardName="Guild"/>
                    {guildsData.guilds.slice(0, MAX_PER_TABLE).map(renderGuildRow)}
                </div>
                {isDesktop && renderRightTable &&
                    <div className="gofrens-top-leaderboard_boards_left">
                        <GoFrensTopLeaderboardCommunityTableHeader leaderboardName="Guild"/>
                        {guildsData.guilds.slice(MAX_PER_TABLE, MAX_PER_TABLE * 2).map(renderGuildRow)}
                    </div>
                }
            </>
        );
    }

    function renderGuildRow(guild: TopGuildLeaderboard, index: number): ReactElement {
        return <GoFrensLeaderboardRow
            key={`guild-${guild.name}`}
            rank={guild.rank}
            profile={{
                photo: guild.icon,
                name: guild.name,
            }}
            accumulatedPoints={guild.totalPoints}
            bgColor={getBgColor(index, isOnDarkMode)}
        />;
    }

    function renderLeaderRow(leader: TopGuildLeaderLeaderboard, index: number): ReactElement {
        return <GoFrensLeaderboardRow
            key={`leader-${leader.guildName}`}
            rank={leader.rank}
            profile={{
                photo: leader.profilePicture,
                name: leader.nickname || "GoVIP Player",
            }}
            accumulatedPoints={leader.totalPoints}
            bgColor={getBgColor(index, isOnDarkMode)}
            communityName={leader.guildName}
            communityIcon={leader.guildIcon}
        />;
    }

    function renderLeaders(): ReactElement {
        if (!leadersData || isLeadersDataFetching) {
            return <GoFrensTopLeaderboardSkeleton/>;
        } if (leadersData.leaders.length === 0) {
            return <GoFrensTopLeaderboardCommunityLeadersEmptyState
                headerText="There are no top guild leaders atm..."
                contentText="We are waiting for guild leaders to be listed on this leaderboard. Start by joining a guild and earning XP to see yourself here!"
                isOnGuildsPage={true}
            />;
        }

        const renderRightTable = leadersData.leaders.length > MAX_PER_TABLE;

        return (
            <>
                <div className="gofrens-top-leaderboard_boards_right">
                    <GoFrensTopLeaderboardCommunityTableHeader leaderboardName="Guild"/>
                    {leadersData.leaders.slice(0, MAX_PER_TABLE).map(renderLeaderRow)}
                </div>
                {isDesktop && renderRightTable &&
                    <div className="gofrens-top-leaderboard_boards_left">
                        <GoFrensTopLeaderboardCommunityTableHeader leaderboardName="Guild"/>
                        {leadersData.leaders.slice(MAX_PER_TABLE, MAX_PER_TABLE * 2).map(renderLeaderRow)}
                    </div>
                }
            </>
        );
    }

    function viewTopButton(toggleModal: () => void, disabled: boolean): ReactElement {
        return (
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color="white-drop-shadow"
                size="normal"
                className="gofrens-top-leaderboard_top-inputs_button"
                onClick={toggleModal}
                forwardProps={{disabled}}
            >
                View Top 50
            </FrameButton>
        );
    }

    function toggleModal(): void {
        setShowLeaderboardModal(!showLeaderboardModal);
    }

    return (
        <div
            id="guilds-top-leaderboard"
            className="guilds-top-leaderboard guilds-page_section">
            <GuildsTopLeaderboardModal
                isOpen={showLeaderboardModal}
                onClose={toggleModal}
                renderFunction={leaderboardView === GuildLeaderboardView.GUILDS ? renderGuildRow : renderLeaderRow}
                initialDateValue={dateValue}
                currentView={leaderboardView}
                periodViewMode={viewMode}
            />

            <div className="guilds-top-leaderboard_header">
                <div className="guilds-top-leaderboard_header_main">
                    <h3 className="guilds-page_section_header">Top Leaderboard</h3>
                    {leaderboardViewModeButtonContent(viewMode, toggleViewMode, isOnDarkMode, isDesktop || isTablet)}
                </div>

                <div className="guilds-top-leaderboard_header_btns">
                    <FrameOneSwitchInput
                        <LeaderboardView>
                        name="leaderboard-view-toggle"
                        className="guilds-top-leaderboard_header_btns_switch"
                        value={leaderboardView}
                        onToggle={toggleLeaderboardView}
                        options={[
                            {
                                render: "Guilds",
                                value: GuildLeaderboardView.GUILDS,
                            },
                            {
                                render: "Guild Leaders",
                                value: GuildLeaderboardView.LEADERS,
                            },
                        ]}
                    />

                    <div className="guilds-top-leaderboard_header_btns_main">
                        {renderDateDropdown()}
                        {viewTopButton(toggleModal, leaderboardView === GuildLeaderboardView.GUILDS ? !guildsData : !leadersData)}
                    </div>
                </div>
            </div>

            <div className="gofrens-top-leaderboard_boards guilds-top-leaderboard_boards">
                {leaderboardView === GuildLeaderboardView.GUILDS
                    ? renderLeaderboards()
                    : renderLeaders()}
            </div>
        </div>
    );
}
