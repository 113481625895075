/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    Challenge,
    ChallengeFromJSON,
    ChallengeFromJSONTyped,
    ChallengeToJSON,
    PrizePool,
    PrizePoolFromJSON,
    PrizePoolFromJSONTyped,
    PrizePoolToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection tracks which users are participating in which challenges. User must accept a challenge to participate.
 * @export
 * @interface ChallengeMembership
 */
export interface ChallengeMembership {
    /**
     * The ID of the user participating in the challenge.
     * @type {string}
     * @memberof ChallengeMembership
     */
    userId: string;
    /**
     * 
     * @type {Challenge}
     * @memberof ChallengeMembership
     */
    challenge: Challenge;
    /**
     * 
     * @type {PrizePool}
     * @memberof ChallengeMembership
     */
    prizeWon?: PrizePool;
    /**
     * The timestamp when the user claimed the rewards for this challenge.
     * @type {number}
     * @memberof ChallengeMembership
     */
    rewardClaimDate?: number;
    /**
     * 
     * @type {string}
     * @memberof ChallengeMembership
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ChallengeMembership
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof ChallengeMembership
     */
    updatedAt: number;
}

export function ChallengeMembershipFromJSON(json: any): ChallengeMembership {
    return ChallengeMembershipFromJSONTyped(json, false);
}

export function ChallengeMembershipFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChallengeMembership {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'challenge': ChallengeFromJSON(json['challenge']),
        'prizeWon': !exists(json, 'prizeWon') ? undefined : PrizePoolFromJSON(json['prizeWon']),
        'rewardClaimDate': !exists(json, 'rewardClaimDate') ? undefined : json['rewardClaimDate'],
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function ChallengeMembershipToJSON(value?: ChallengeMembership | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'challenge': ChallengeToJSON(value.challenge),
        'prizeWon': PrizePoolToJSON(value.prizeWon),
        'rewardClaimDate': value.rewardClaimDate,
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}


