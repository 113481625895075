import {ReactElement} from "react";
import {FaDiscord, FaTwitter} from "react-icons/fa";
import {TbWorld} from "react-icons/tb";
import {IoArrowBack} from "react-icons/io5";

interface Props {
    toggle: () => void;
    websiteUrl?: string;
    twitterUrl?: string;
    discordUrl?: string;
    hero?: string;
    icon?: string;
    name?: string;
}

function CommunityDrawerHeaderGraphic(props: Props): ReactElement {

    return (
        <div className="gofrens-community-info-drawer_contents_top-graphic">
            <div className="gofrens-community-info-drawer_contents_top-graphic_hero">
                <div className="gofrens-community-info-drawer_contents_top-graphic_hero_info">

                    <span className="gofrens-community-info-drawer_contents_top-graphic_hero_info_back">
                        <IoArrowBack
                            size="1.75rem"
                            onClick={props.toggle}
                        />
                    </span>

                    <div className="gofrens-community-info-drawer_contents_top-graphic_hero_info_social">
                        {props.websiteUrl &&
                            <TbWorld
                                className="gofrens-community-info-drawer_contents_top-graphic_hero_info_social_icon"
                                onClick={() => window.open(props.websiteUrl, "_blank")}
                            />
                        }
                        {props.twitterUrl &&
                            <FaTwitter
                                className="gofrens-community-info-drawer_contents_top-graphic_hero_info_social_icon"
                                onClick={() => window.open(props.twitterUrl, "_blank")}
                            />
                        }
                        {props.discordUrl &&
                            <FaDiscord
                                className="gofrens-community-info-drawer_contents_top-graphic_hero_info_social_icon"
                                onClick={() => window.open(props.discordUrl, "_blank")}
                            />
                        }
                    </div>
                </div>
                {props.hero && <img
                    src={props.hero}
                    alt={`${props.name ?? ""} hero`}
                    className="gofrens-community-info-drawer_contents_top-graphic_hero_img"
                />}
            </div>
            <div className="gofrens-community-info-drawer_contents_top-graphic_info">
                <div className="gofrens-community-info-drawer_contents_top-graphic_icon">
                    {props.icon && <img
                        src={props.icon}
                        alt={`${props.name ?? ""} icon`}
                    />}
                </div>
                <h3 className="gofrens-community-info-drawer_contents_top-graphic_title">
                    {props.name}
                </h3>
            </div>
        </div>
    );
}

export default CommunityDrawerHeaderGraphic;
