/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    AutocompleteAddressResponse,
    AutocompleteAddressResponseFromJSON,
    AutocompleteAddressResponseToJSON,
    ConfigResponse,
    ConfigResponseFromJSON,
    ConfigResponseToJSON,
    DialCode,
    DialCodeFromJSON,
    DialCodeToJSON,
    GenericCounter,
    GenericCounterFromJSON,
    GenericCounterToJSON,
    GetGeography,
    GetGeographyFromJSON,
    GetGeographyToJSON,
    InlineResponse20021,
    InlineResponse20021FromJSON,
    InlineResponse20021ToJSON,
    InlineResponse20022,
    InlineResponse20022FromJSON,
    InlineResponse20022ToJSON,
    LevelUpTable,
    LevelUpTableFromJSON,
    LevelUpTableToJSON,
    VersionResponse,
    VersionResponseFromJSON,
    VersionResponseToJSON,
} from '../models';

export interface GetAddressAutocompleteRequest {
    formattedAddress?: string;
}

export interface GetAddressFromLatLngRequest {
    latitude?: string;
    longitude?: string;
}

export interface GetAddressFromPlaceIdRequest {
    placeId?: string;
}

export interface GetDpayOnchainTransactionRequest {
    id: string;
}

export interface GetGenericCounterRequest {
    key?: string;
}

export interface GetPlaceIdFromAddressRequest {
    address?: Address;
}

export interface GetPostalCodeAutocompleteRequest {
    postalCode?: string;
}

/**
 * UtilsApi - interface
 * @export
 * @interface UtilsApiInterface
 */
export interface UtilsApiInterface {
    /**
     * Used to parse autocomplete from address
     * @param {string} [formattedAddress] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    getAddressAutocompleteRaw(requestParameters: GetAddressAutocompleteRequest): Promise<runtime.ApiResponse<AutocompleteAddressResponse>>;

    /**
     * Used to parse autocomplete from address
     */
    getAddressAutocomplete(requestParameters: GetAddressAutocompleteRequest): Promise<AutocompleteAddressResponse>;

    /**
     * Used to get address body from place id
     * @param {string} [latitude] 
     * @param {string} [longitude] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    getAddressFromLatLngRaw(requestParameters: GetAddressFromLatLngRequest): Promise<runtime.ApiResponse<Address>>;

    /**
     * Used to get address body from place id
     */
    getAddressFromLatLng(requestParameters: GetAddressFromLatLngRequest): Promise<Address>;

    /**
     * Used to get address body from place id
     * @param {string} [placeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    getAddressFromPlaceIdRaw(requestParameters: GetAddressFromPlaceIdRequest): Promise<runtime.ApiResponse<Address>>;

    /**
     * Used to get address body from place id
     */
    getAddressFromPlaceId(requestParameters: GetAddressFromPlaceIdRequest): Promise<Address>;

    /**
     * Used to get various configs for the system.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    getConfigRaw(): Promise<runtime.ApiResponse<ConfigResponse>>;

    /**
     * Used to get various configs for the system.
     */
    getConfig(): Promise<ConfigResponse>;

    /**
     * Used to transaction data of a DPAY transaction
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    getDpayOnchainTransactionRaw(requestParameters: GetDpayOnchainTransactionRequest): Promise<runtime.ApiResponse<InlineResponse20021>>;

    /**
     * Used to transaction data of a DPAY transaction
     */
    getDpayOnchainTransaction(requestParameters: GetDpayOnchainTransactionRequest): Promise<InlineResponse20021>;

    /**
     * Gets the model of a generic counter in the database.
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    getGenericCounterRaw(requestParameters: GetGenericCounterRequest): Promise<runtime.ApiResponse<GenericCounter>>;

    /**
     * Gets the model of a generic counter in the database.
     */
    getGenericCounter(requestParameters: GetGenericCounterRequest): Promise<GenericCounter>;

    /**
     * Used to get a list of all countries and their phone dial codes. Also includes Canadian provinces and USA states.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    getGeographyRaw(): Promise<runtime.ApiResponse<GetGeography>>;

    /**
     * Used to get a list of all countries and their phone dial codes. Also includes Canadian provinces and USA states.
     */
    getGeography(): Promise<GetGeography>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    getLevelUpTableRaw(): Promise<runtime.ApiResponse<LevelUpTable>>;

    /**
     */
    getLevelUpTable(): Promise<LevelUpTable>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    getListOfDialCodesRaw(): Promise<runtime.ApiResponse<Array<DialCode>>>;

    /**
     */
    getListOfDialCodes(): Promise<Array<DialCode>>;

    /**
     * Used to get place id from address body
     * @param {Address} [address] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    getPlaceIdFromAddressRaw(requestParameters: GetPlaceIdFromAddressRequest): Promise<runtime.ApiResponse<InlineResponse20022>>;

    /**
     * Used to get place id from address body
     */
    getPlaceIdFromAddress(requestParameters: GetPlaceIdFromAddressRequest): Promise<InlineResponse20022>;

    /**
     * Used to parse autocomplete from postal code
     * @param {string} [postalCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    getPostalCodeAutocompleteRaw(requestParameters: GetPostalCodeAutocompleteRequest): Promise<runtime.ApiResponse<AutocompleteAddressResponse>>;

    /**
     * Used to parse autocomplete from postal code
     */
    getPostalCodeAutocomplete(requestParameters: GetPostalCodeAutocompleteRequest): Promise<AutocompleteAddressResponse>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    getTimeZonesRaw(): Promise<runtime.ApiResponse<Array<string>>>;

    /**
     */
    getTimeZones(): Promise<Array<string>>;

    /**
     * Should always return a status code of 200. Used to determine if the server is up and working.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    healthRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * Should always return a status code of 200. Used to determine if the server is up and working.
     */
    health(): Promise<void>;

    /**
     * Inflate the \"type\" field for old documents.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    inflateWalletTypesRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * Inflate the \"type\" field for old documents.
     */
    inflateWalletTypes(): Promise<void>;

    /**
     * A safe public API to initialize the server. This API is sustainable to even if compromised, it will not cause problems.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    initRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * A safe public API to initialize the server. This API is sustainable to even if compromised, it will not cause problems.
     */
    init(): Promise<void>;

    /**
     * A safe public API to initialize the server. This API is sustainable to even if compromised, it will not cause problems.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    migrateFdPhoneNumbersRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * A safe public API to initialize the server. This API is sustainable to even if compromised, it will not cause problems.
     */
    migrateFdPhoneNumbers(): Promise<void>;

    /**
     * Returns details about the current mongo connection through mongoose.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    mongoInformationRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * Returns details about the current mongo connection through mongoose.
     */
    mongoInformation(): Promise<void>;

    /**
     * Finds what version of the app you are using
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApiInterface
     */
    versionRaw(): Promise<runtime.ApiResponse<VersionResponse>>;

    /**
     * Finds what version of the app you are using
     */
    version(): Promise<VersionResponse>;

}

/**
 * no description
 */
export class UtilsApi extends runtime.BaseAPI implements UtilsApiInterface {

    /**
     * Used to parse autocomplete from address
     */
    async getAddressAutocompleteRaw(requestParameters: GetAddressAutocompleteRequest): Promise<runtime.ApiResponse<AutocompleteAddressResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.formattedAddress !== undefined) {
            queryParameters['formattedAddress'] = requestParameters.formattedAddress;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/address/autocomplete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AutocompleteAddressResponseFromJSON(jsonValue));
    }

    /**
     * Used to parse autocomplete from address
     */
    async getAddressAutocomplete(requestParameters: GetAddressAutocompleteRequest): Promise<AutocompleteAddressResponse> {
        const response = await this.getAddressAutocompleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get address body from place id
     */
    async getAddressFromLatLngRaw(requestParameters: GetAddressFromLatLngRequest): Promise<runtime.ApiResponse<Address>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.latitude !== undefined) {
            queryParameters['latitude'] = requestParameters.latitude;
        }

        if (requestParameters.longitude !== undefined) {
            queryParameters['longitude'] = requestParameters.longitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/address/from-lat-lng`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     * Used to get address body from place id
     */
    async getAddressFromLatLng(requestParameters: GetAddressFromLatLngRequest): Promise<Address> {
        const response = await this.getAddressFromLatLngRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get address body from place id
     */
    async getAddressFromPlaceIdRaw(requestParameters: GetAddressFromPlaceIdRequest): Promise<runtime.ApiResponse<Address>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.placeId !== undefined) {
            queryParameters['placeId'] = requestParameters.placeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/address/from-place-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     * Used to get address body from place id
     */
    async getAddressFromPlaceId(requestParameters: GetAddressFromPlaceIdRequest): Promise<Address> {
        const response = await this.getAddressFromPlaceIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get various configs for the system.
     */
    async getConfigRaw(): Promise<runtime.ApiResponse<ConfigResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/utils/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigResponseFromJSON(jsonValue));
    }

    /**
     * Used to get various configs for the system.
     */
    async getConfig(): Promise<ConfigResponse> {
        const response = await this.getConfigRaw();
        return await response.value();
    }

    /**
     * Used to transaction data of a DPAY transaction
     */
    async getDpayOnchainTransactionRaw(requestParameters: GetDpayOnchainTransactionRequest): Promise<runtime.ApiResponse<InlineResponse20021>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDpayOnchainTransaction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/utils/get-dpay-onchain-transaction/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20021FromJSON(jsonValue));
    }

    /**
     * Used to transaction data of a DPAY transaction
     */
    async getDpayOnchainTransaction(requestParameters: GetDpayOnchainTransactionRequest): Promise<InlineResponse20021> {
        const response = await this.getDpayOnchainTransactionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets the model of a generic counter in the database.
     */
    async getGenericCounterRaw(requestParameters: GetGenericCounterRequest): Promise<runtime.ApiResponse<GenericCounter>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/utils/generic-counter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GenericCounterFromJSON(jsonValue));
    }

    /**
     * Gets the model of a generic counter in the database.
     */
    async getGenericCounter(requestParameters: GetGenericCounterRequest): Promise<GenericCounter> {
        const response = await this.getGenericCounterRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get a list of all countries and their phone dial codes. Also includes Canadian provinces and USA states.
     */
    async getGeographyRaw(): Promise<runtime.ApiResponse<GetGeography>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/utils/get-geography`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGeographyFromJSON(jsonValue));
    }

    /**
     * Used to get a list of all countries and their phone dial codes. Also includes Canadian provinces and USA states.
     */
    async getGeography(): Promise<GetGeography> {
        const response = await this.getGeographyRaw();
        return await response.value();
    }

    /**
     */
    async getLevelUpTableRaw(): Promise<runtime.ApiResponse<LevelUpTable>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/utils/level-up-table`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LevelUpTableFromJSON(jsonValue));
    }

    /**
     */
    async getLevelUpTable(): Promise<LevelUpTable> {
        const response = await this.getLevelUpTableRaw();
        return await response.value();
    }

    /**
     */
    async getListOfDialCodesRaw(): Promise<runtime.ApiResponse<Array<DialCode>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/utils/get-dial-codes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DialCodeFromJSON));
    }

    /**
     */
    async getListOfDialCodes(): Promise<Array<DialCode>> {
        const response = await this.getListOfDialCodesRaw();
        return await response.value();
    }

    /**
     * Used to get place id from address body
     */
    async getPlaceIdFromAddressRaw(requestParameters: GetPlaceIdFromAddressRequest): Promise<runtime.ApiResponse<InlineResponse20022>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/address/to-place-id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddressToJSON(requestParameters.address),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20022FromJSON(jsonValue));
    }

    /**
     * Used to get place id from address body
     */
    async getPlaceIdFromAddress(requestParameters: GetPlaceIdFromAddressRequest): Promise<InlineResponse20022> {
        const response = await this.getPlaceIdFromAddressRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to parse autocomplete from postal code
     */
    async getPostalCodeAutocompleteRaw(requestParameters: GetPostalCodeAutocompleteRequest): Promise<runtime.ApiResponse<AutocompleteAddressResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.postalCode !== undefined) {
            queryParameters['postalCode'] = requestParameters.postalCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/address/autocomplete/postal-code`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AutocompleteAddressResponseFromJSON(jsonValue));
    }

    /**
     * Used to parse autocomplete from postal code
     */
    async getPostalCodeAutocomplete(requestParameters: GetPostalCodeAutocompleteRequest): Promise<AutocompleteAddressResponse> {
        const response = await this.getPostalCodeAutocompleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getTimeZonesRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/utils/get-time-zones`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getTimeZones(): Promise<Array<string>> {
        const response = await this.getTimeZonesRaw();
        return await response.value();
    }

    /**
     * Should always return a status code of 200. Used to determine if the server is up and working.
     */
    async healthRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Should always return a status code of 200. Used to determine if the server is up and working.
     */
    async health(): Promise<void> {
        await this.healthRaw();
    }

    /**
     * Inflate the \"type\" field for old documents.
     */
    async inflateWalletTypesRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("GodAdmin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inflate-wallet-types`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Inflate the \"type\" field for old documents.
     */
    async inflateWalletTypes(): Promise<void> {
        await this.inflateWalletTypesRaw();
    }

    /**
     * A safe public API to initialize the server. This API is sustainable to even if compromised, it will not cause problems.
     */
    async initRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/init`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * A safe public API to initialize the server. This API is sustainable to even if compromised, it will not cause problems.
     */
    async init(): Promise<void> {
        await this.initRaw();
    }

    /**
     * A safe public API to initialize the server. This API is sustainable to even if compromised, it will not cause problems.
     */
    async migrateFdPhoneNumbersRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/migrate-fd-phone-numbers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * A safe public API to initialize the server. This API is sustainable to even if compromised, it will not cause problems.
     */
    async migrateFdPhoneNumbers(): Promise<void> {
        await this.migrateFdPhoneNumbersRaw();
    }

    /**
     * Returns details about the current mongo connection through mongoose.
     */
    async mongoInformationRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mongo_information`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Returns details about the current mongo connection through mongoose.
     */
    async mongoInformation(): Promise<void> {
        await this.mongoInformationRaw();
    }

    /**
     * Finds what version of the app you are using
     */
    async versionRaw(): Promise<runtime.ApiResponse<VersionResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionResponseFromJSON(jsonValue));
    }

    /**
     * Finds what version of the app you are using
     */
    async version(): Promise<VersionResponse> {
        const response = await this.versionRaw();
        return await response.value();
    }

}
