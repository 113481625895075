import {ReactElement, useState} from "react";
import {Guild, NftGrouping} from "@devour/client";
import {truncateText} from "@/utils/truncateText";
import {getRankSuffix} from "@/utils/getRankSuffix";

interface Props {
    description?: string;
    guild?: Guild;
    memberCount?: number;
    rank?: number;
}

function CommunityGuildInfo(props: Props): ReactElement {

    const [showAllText, setShowAllText] = useState<boolean>(false);

    function getDescription(): string {
        if (props.description) {
            return props.description;
        } else if (props.guild) {
            return `Create, play, and battle with friends in ${props.guild.name}. Join the community by earning an exclusive 
            ${props.guild.name} NFT through completing quests on DevourPlay.`;
        }
    }

    const description = getDescription();

    return (
        <div className={!props.guild ? "gofrens-community-info-drawer_contents_info" : ""}>
            {description &&
                <p className="gofrens-community-info-drawer_contents_info_description">
                    {showAllText ? `${description} ` : `${truncateText(140, description)} `}
                    <span onClick={() => setShowAllText(!showAllText)}>
                        {showAllText
                            ? "Read Less"
                            : "Read More"}
                    </span>
                </p>
            }
            <div className="gofrens-community-info-drawer_contents_info_community-stats">
                <div className="gofrens-community-info-drawer_contents_info_community-stats_section">
                    <p className="gofrens-community-info-drawer_contents_info_community-stats_section_header">
                        {props.guild ? "Monthly" : "Community"} Rank
                    </p>
                    <div
                        className="gofrens-community-info-drawer_contents_info_community-stats_section_card">
                        <div>{props.rank == null
                            ? <span className="gofrens-community-info-drawer_contents_info_community-stats_section_card_unranked">
                                Unranked
                            </span>
                            : <>
                                <span className="gofrens-community-info-drawer_contents_info_community-stats_section_card_rank">
                                    {props.rank}
                                </span>
                                {` ${getRankSuffix(props.rank)}`}
                            </>
                        }
                        </div>
                    </div>
                </div>
                <div className="gofrens-community-info-drawer_contents_info_community-stats_section">
                    <p className="gofrens-community-info-drawer_contents_info_community-stats_section_header">
                        Total Members
                    </p>
                    <div
                        className="gofrens-community-info-drawer_contents_info_community-stats_section_card">
                        <div>
                            <span
                                className="gofrens-community-info-drawer_contents_info_community-stats_section_card_rank"
                            >
                                {props.memberCount}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CommunityGuildInfo;
