import {ReactElement} from "react";
import {RankedMember} from "@devour/client";
import GoFrensLeaderboardRow from "@/components/goFrens/GoFrensLeaderboardRow";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import useThemePreference from "@/hooks/useThemePreference";
import GoFrensTopMembersEmptyState from "@/components/goFrens/GoFrensTopMembersEmptyState";
import GoFrensTopLeaderboardCommunityTableHeader
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardCommunityTableHeader";

interface Props {
    rankData: Array<RankedMember>;
    className?: string;
    isModal?: boolean;
    isCurrentAllTime?: boolean;
    isAroundUserRanking?: boolean;
}
function GoFrensMyCommunityLeaderboardBoard(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: userData} = useGetUserProfile(fullToken);
    const {isOnDarkMode} = useThemePreference();
    const rankedUser = props.rankData?.find(member => member.id === userData.user?.id && member.pointsEarnedForCommunity > 0);
    const myRankIcon = <span className="my-rank-icon">Me</span>;

    function renderBoardRow(member: RankedMember, index: number) {
        const bgColor = props.isAroundUserRanking
            ? "white"
            : (index % 2 === 0 ? "light-gray" : "white");

        return (
            <GoFrensLeaderboardRow
                key={member.id}
                rank={member.rankWithinCommunity}
                profile={{
                    name: member.nickname || "GoVIP Player",
                    photo: member.gravatar,
                }}
                accumulatedPoints={member.pointsEarnedForCommunity}
                rightIconToName={
                    props.isAroundUserRanking && userData.user?.id === member.id && myRankIcon
                }
                bgColor={`${isOnDarkMode ? "DM-" : ""}${bgColor}`}
                className={userData.user?.id === member.id && "gofrens-leaderboard-row_my-rank"}
            />
        );
    }

    if (!userData) {
        return;
    }

    // user is not ranked
    if (props.isAroundUserRanking && !rankedUser) {
        return (
            <div className="my-community-leaderboard_user-unranked">
                <p>Your rank leaderboard will be displayed here along with two other competitors, once you have more
                than 1 XP.</p>
                <GoFrensLeaderboardRow
                    profile={{
                        name: userData.user.nickname || "GoVIP Player",
                        photo: userData.user.gravatar,
                    }}
                    accumulatedPoints="0 XP"
                    className="gofrens-leaderboard-row_user-unranked"
                    bgColor={isOnDarkMode ? "DM-light-gray" : "light-gray"}
                />
            </div>
        );
    }

    if (!props.rankData.length) {
        return <GoFrensTopMembersEmptyState
            isCurrentAllTime={props.isCurrentAllTime}
            isModal={props.isModal}/>;
    }

    return (
        <div className="my-community-leaderboard">
            <GoFrensTopLeaderboardCommunityTableHeader />
            <div className="my-community-leaderboard_body">
                {props.rankData.map(renderBoardRow)}
            </div>
        </div>
    );
}

export default GoFrensMyCommunityLeaderboardBoard;