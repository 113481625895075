import Skeleton from "@/components/skeletons/Skeleton";
import { useGetOverwolfSummary } from "@/hooks/useGetOverwolfSummary";
import { IStore } from "@/redux/defaultStore";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RiVipDiamondLine } from "react-icons/ri";
import { FaRegClock } from "react-icons/fa";
import { IoGameControllerOutline } from "react-icons/io5";
import { isMobile } from "react-device-detect";
import Tooltip from "@/components/Tooltip";

export const OverwolfGoVipSummary = () => {
    const { data, refetch } = useGetOverwolfSummary();
    const owGameInfo = useSelector((store: IStore) => store.metaStore.overwolfGameInfo);

    function formatMinutes(totalMinutes: number) {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return (
            <>
                {hours}
                <span className="time-label">h</span> {minutes}
                <span className="time-label">m</span>
            </>
        );
    }

    useEffect(() => {
        let refetchInterval = null;
        // If there's a game running it will refetch every 2.5 minutes to update time played and played games
        if (owGameInfo) {
            refetchInterval = setInterval(() => {
                refetch();
            }, 150000);
        }
        return () => {
            if (refetchInterval) {
                clearInterval(refetchInterval);
            }
        };
    }, [owGameInfo]);

    const getDataSkeleton = () => {
        return <Skeleton width={isMobile ? "40px" : "80px"} rows={1} height={13} />;
    };

    return (
        <div className="overwolf-govip-summary">
            <div className="overwolf-govip-summary_data small_width">
                <h5 className="overwolf-govip-summary_data_value">
                    {!isMobile ? <RiVipDiamondLine/> : <Tooltip outsideIcon={RiVipDiamondLine} containerElementClassname="overwolf-govip-summary">
                        <div className="overwolf-govip_rewards_header_tooltip_content">Total XP earned</div>
                    </Tooltip>}
                    <span>{data ? data.totalXp : getDataSkeleton()}</span>
                </h5>
                <span className="overwolf-govip-summary_data_label">Total XP earned</span>
            </div>
            <div className="overwolf-govip-summary_divider"></div>
            <div className="overwolf-govip-summary_data">
                <h5 className="overwolf-govip-summary_data_value">
                    {!isMobile ? <FaRegClock/> : <Tooltip outsideIcon={FaRegClock} containerElementClassname="overwolf-govip-summary">
                        <div className="overwolf-govip_rewards_header_tooltip_content">Total time played</div>
                    </Tooltip>}
                    <span>{data ? formatMinutes(data?.totalTime ?? 0) : getDataSkeleton()}</span>
                </h5>
                <span className="overwolf-govip-summary_data_label">Time played</span>
            </div>
            <div className="overwolf-govip-summary_divider"></div>
            <div className="overwolf-govip-summary_data small_width">
                <h5 className="overwolf-govip-summary_data_value io-icon">
                    {!isMobile ? <IoGameControllerOutline/>
                        : <Tooltip
                            className="io-icon"
                            outsideIcon={IoGameControllerOutline}
                            containerElementClassname="overwolf-govip-summary"
                        >
                            <div className="overwolf-govip_rewards_header_tooltip_content">Played games</div>
                        </Tooltip>}
                    <span>{data ? data?.totalGames : getDataSkeleton()}</span>
                </h5>
                <span className="overwolf-govip-summary_data_label">Played games</span>
            </div>
        </div>
    );
};
