export function isColorDarkOrLight(color: string) {
    const hexToRgb = (hex) => {
        hex = hex.replace("#", "");
        if (hex.length === 3) {
            hex = hex.split("").map((char) => char + char)
                .join("");
        }
        const bigint = parseInt(hex, 16);
        return [bigint >> 16, bigint >> 8 & 255, bigint & 255];
    };

    const getRgbArray = (color) => {
        if (color.startsWith("#")) {
            return hexToRgb(color);
        } if (color.startsWith("rgb")) {
            return color.match(/\d+/g).map(Number);
        }
        const ctx = document.createElement("canvas").getContext("2d");
        ctx.fillStyle = color;
        const computedColor = ctx.fillStyle;
        return hexToRgb(computedColor);

    };

    const getLuminance = ([r, g, b]) => {
        const sRGB = [r, g, b].map((v) => v / 255).map((v) =>
            v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4);
        return 0.2126 * sRGB[0] + 0.7152 * sRGB[1] + 0.0722 * sRGB[2];
    };

    const rgb = getRgbArray(color);
    const luminance = getLuminance(rgb);
    return luminance > 0.5 ? "light" : "dark";
}
