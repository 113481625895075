import {Guild} from "@devour/client";
import {useAsset} from "@/hooks/useAsset";
import useGetOverwolfGameInfo from "@/hooks/useGetOverwolfGameInfo";
import {isDesktop, isTablet} from "react-device-detect";
import {BiCommentError} from "react-icons/bi";
import Dropdown from "@/components/Dropdown";
import useGetCurrentGuildTopMembers from "@/hooks/useGetCurrentGuildTopMembers";
import {getDateInEst} from "@/utils/getDateInEst";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {BsThreeDotsVertical} from "react-icons/bs";
import {RiVipDiamondLine} from "react-icons/ri";
import {getRankSuffix} from "@/utils/getRankSuffix";
import {ButtonHTMLAttributes, ReactElement, useState} from "react";
import {FiChevronRight} from "react-icons/fi";
import FrameButton from "@/components/buttons/FrameButton";
import {IoMdExit} from "react-icons/io";
import LeaveGuildModal from "@/components/modals/guilds/LeaveGuildModal";
import GuildInfoDrawer from "@/pages/guilds/GuildInfoDrawer";
import {ViewMode} from "@/components/goFrens/GoFrensTopLeaderboard";
import FrameOneSwitchInput from "@/components/inputs/FrameOneSwitchInput";

interface Props {
    guild: Guild
}
const DISPLAY_COUNT_THRESHOLD = 5;

export default function GuildMemberHeader(props: Props) {
    const {guild} = props;
    const {data: icon} = useAsset(props.guild?.icon);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: overwolfGameInfo} = useGetOverwolfGameInfo(props.guild?.games[0]);
    const {data: backgroundImg} = useAsset(isDesktop ? overwolfGameInfo?.bannerImageLarge : overwolfGameInfo?.bannerImageSmall);

    const [showLeaveGuildModal, setShowLeaveGuildModal] = useState<boolean>(false);
    const [showInfoDrawer, setShowInfoDrawer] = useState<boolean>(false);
    const [rankViewMode, setRankViewMode] = useState<ViewMode>(ViewMode.MONTHLY);
    const now = getDateInEst();
    const dateParam = `${now.getMonth() + 1},${now.getFullYear()}`;
    const {data: topMembersData} = useGetCurrentGuildTopMembers(fullToken, `${dateParam},0`, true);
    const members = topMembersData?.members;

    if (!guild || !overwolfGameInfo) {
        return <div className="guilds-header react-loading-skeleton" />;
    }

    function renderMemberPfp() {
        if (!members) {
            return;
        }

        const counterToBeDisplayed = guild.memberCount > DISPLAY_COUNT_THRESHOLD ? guild.memberCount - DISPLAY_COUNT_THRESHOLD : guild.memberCount;
        const textCounterToBeDisplayed = `${guild.memberCount > DISPLAY_COUNT_THRESHOLD ? "+" : ""}${counterToBeDisplayed} Member${counterToBeDisplayed === 1 ? "" : "s"}`;
        return (
            <>
                {guild.memberCount > DISPLAY_COUNT_THRESHOLD &&
                    <div className="guild-member-header_left_info_members_pfps">
                        {members.slice(0, DISPLAY_COUNT_THRESHOLD).map(member => <img
                            src={member.profilePicture}
                            alt="govip player avatar"
                            key={`pfp-${member.id}`}
                        />)}
                    </div>
                }
                <div className="guild-member-header_left_info_members_more">{textCounterToBeDisplayed}</div>
            </>
        );
    }

    function scrollToLeaderboard() {
        document.getElementById("guilds-top-leaderboard")?.scrollIntoView({behavior: "smooth"});
    }

    function toggleGuildInfoDrawer() {
        setShowInfoDrawer(!showInfoDrawer);
    }

    function dropdownBody() {
        return <>
            <div
                className="devour-top-nav_dropdown_account_options_row"
                onClick={toggleGuildInfoDrawer}
            >
                <BiCommentError className="community-about"/>
                <p>About</p>
            </div>
            <hr/>
            <div
                className="devour-top-nav_dropdown_account_options_row devour-top-nav_dropdown_account_options_logout"
                onClick={() => setShowLeaveGuildModal(true)}
            >
                <IoMdExit strokeWidth="0.675rem"/>
                <p>Leave This Guild</p>
            </div>
        </>;
    }

    function renderRank(): ReactElement {
        const rank = rankViewMode === ViewMode.MONTHLY ? guild.monthlyRank : guild.rank;
        const points = rankViewMode === ViewMode.MONTHLY ? guild.monthlyPoints : guild.totalPoints;

        return <div>
            <div className="guild-member-header_right_rank_info">
                <RiVipDiamondLine
                    size="21px"
                    strokeWidth="0.075rem"
                />
                <h3 className={rank && "ranked"}>{rank || "Unranked"}</h3>
                <span>{getRankSuffix(rank)}</span>
            </div>

            <div className="guild-member-header_right_rank_xp">{points} XP</div>
        </div>;
    }

    return (
        <>
            {guild && <GuildInfoDrawer
                isOpen={showInfoDrawer}
                toggle={toggleGuildInfoDrawer}
                guild={props.guild}
            />}
            {guild && <LeaveGuildModal
                guild={guild}
                isOpen={showLeaveGuildModal}
                toggle={() => setShowLeaveGuildModal(!showLeaveGuildModal)}
            />}
            <div
                className="guild-member-header"
                style={{backgroundImage: `url(${backgroundImg?.url})`}}
            >
                <div className="guild-member-header_left">
                    <div className="guild-member-header_left_graphic-container">
                        {icon && <img
                            className="gofrens-community-header_left_graphic"
                            src={icon?.url}
                            alt={`${guild.name} icon`}
                        />}
                    </div>
                    <div className="guild-member-header_left_info">
                        <div className="guild-member-header_left_info_name">
                            <h3>{guild.name}</h3>
                            <Dropdown
                                dropdownBody={dropdownBody()}
                                dropdownBodyClassName="gofrens-community-header-dropdown"
                                dropdownContainerClassName="guild-member-header_left_info_name_toggle"
                                dropdownToggleElement={
                                    <BsThreeDotsVertical size="24px" className="guild-member-header_left_info_name_icon"/>
                                }
                            />
                        </div>

                        <div className="guild-member-header_left_info_members">
                            {members && renderMemberPfp()}
                        </div>
                    </div>
                </div>
                <div className="guild-member-header_right">
                    <div className="guild-member-header_right_top">
                        <div className="guild-member-header_right_text">Guild Rank</div>
                        <FrameOneSwitchInput
                            <ViewMode>
                            name="leaderboard-view-toggle"
                            className="guild-member-header_right_top_switch"
                            value={rankViewMode}
                            onToggle={() => setRankViewMode(rankViewMode === ViewMode.MONTHLY ? ViewMode.CUMULATIVE : ViewMode.MONTHLY)}
                            options={[
                                {
                                    render: "Monthly",
                                    value: ViewMode.MONTHLY,
                                },
                                {
                                    render: "All-Time",
                                    value: ViewMode.CUMULATIVE,
                                },
                            ]}
                        />
                    </div>
                    <div className="guild-member-header_right_rank">
                        {renderRank()}

                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            size="narrow"
                            rightIcon={FiChevronRight}
                            className="guild-member-header_right_rank_btn"
                            onClick={scrollToLeaderboard}
                        >
                            {isDesktop || isTablet ? "Guild Leaderboard" : "Leaderboard"}
                        </FrameButton>
                    </div>
                </div>
                <div className="gofrens-header_white-rounded guild-member-header_white-rounded"/>
            </div>
        </>
    );
}