import {Guild, GuildsApi} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import {IoMdExit} from "react-icons/io";
import FrameButton from "@/components/buttons/FrameButton";
import {ButtonHTMLAttributes} from "react";
import useThemePreference from "@/hooks/useThemePreference";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import {IStore} from "@/redux/defaultStore";
import {useGetGuildProgress} from "@/hooks/useGetGuildProgress";
import useGetGuildOwnerships from "@/hooks/useGetGuildOwnerships";
import {useQueryClient} from "@tanstack/react-query";

interface Props {
    guild: Guild;
    isOpen: boolean;
    toggle: () => void;
}
export default function LeaveGuildModal(props: Props) {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { refetch: refetchGuildProgress } = useGetGuildProgress(props.guild?.id, fullToken);
    const { refetch: refetchGuildOwnerships } = useGetGuildOwnerships(fullToken, false);
    const { isOnDarkMode } = useThemePreference();

    async function handleLeaveGuild() {
        dispatch(incrementLoading());
        try {
            await new GuildsApi(getConfig()).leaveGuild();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
            props.toggle();
            await Promise.all([
                refetchGuildProgress(),
                refetchGuildOwnerships(),
            ]);
            void queryClient.invalidateQueries({queryKey: ["current-guild-top-members"]});
        }
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="gofrens-leave-community-modal"
        >
            <FrameModalBody className="gofrens-leave-community-modal_body">
                <IoMdExit className="gofrens-leave-community-modal_body_exit-icon"/>
                <h3 className="gofrens-leave-community-modal_body_title">
                    Leave This Guild?
                </h3>
                <p className="gofrens-leave-community-modal_body_disclaimer">
                    You can only join a new guild during the first week of every month. If you leave a guild, you can
                    rejoin at any time, however, note that your XP contributions will be erased and you will start unranked.
                </p>
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color={isOnDarkMode ? "danger-outline" : "danger"}
                    size="narrow"
                    className="gofrens-leave-community-modal_body_confirm-btn"
                    onClick={handleLeaveGuild}
                >
                    Leave Now
                </FrameButton>
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="purple-text-no-border"
                    size="narrow"
                    className="gofrens-leave-community-modal_body_cancel-btn"
                    onClick={props.toggle}
                >
                    Stay with {props.guild.name}
                </FrameButton>
            </FrameModalBody>
        </FrameOneModal>
    );
}