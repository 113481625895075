import {ReactElement} from "react";
import classNames from "classnames";

interface Props {
    isModal?: boolean;
    leaderboardName: string;
}

function GoFrensTopLeaderboardCommunityTableHeader(props: Props): ReactElement {
    return (
        <div className={classNames(
            "my-community-leaderboard_header",
            {"is-modal": props.isModal},
        )}>
            <div className="my-community-leaderboard_header_rank">Rank</div>
            <div className="my-community-leaderboard_header_member">{props.leaderboardName}</div>
            <div className="my-community-leaderboard_header_exp">XP</div>
        </div>
    );
}

GoFrensTopLeaderboardCommunityTableHeader.defaultProps = {
    leaderboardName: "Community",
};

export default GoFrensTopLeaderboardCommunityTableHeader;
