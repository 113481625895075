import FrameButton from "@/components/buttons/FrameButton";
import DevourTopNav from "@/components/DevourTopNav";
import GoFrensNftCard from "@/components/goFrens/GoFrensNftCard";
import GoVipRewardsInventoryDrawer from "@/components/goVip/GoVipRewardsInventoryDrawer";
import FramePaginator, { FrontendPagination } from "@/components/paginator/FramePaginator";
import GoFrensMyNftsSkeleton from "@/components/skeletons/GoFrensPage/GoFrensMyNftsSkeleton";
import { useGetNftOwnerships } from "@/hooks/useGetNftOwnerships";
import { useGetRedeemableNftOwnerships } from "@/hooks/useGetRedeemableNftOwnerships";
import useThemePreference from "@/hooks/useThemePreference";
import { IStore } from "@/redux/defaultStore";
import { incrementLoading, addError, decrementLoading } from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import { getPaginationInfo } from "@/utils/paginationUtils";
import { PaginationInfo, Token, UsersApi } from "@devour/client";
import { useState, ButtonHTMLAttributes, useEffect } from "react";
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import { HiOutlineChevronRight } from "react-icons/hi";
import { MdRefresh } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

const defaultPaginationInfo: PaginationInfo = {
    enablePrevious: true,
    enableNext: true,
    lowerRange: 0,
    upperRange: 0,
    total: 0,
    availableOffsets: [],
    limit: 12,
    offset: 0,
};


export const CollectiblesPage: React.FC = () => {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dispatch = useDispatch();
    const { isOnDarkMode } = useThemePreference();
    const {data: nftOwnershipsData} = useGetNftOwnerships(fullToken as Token);
    const count = nftOwnershipsData?.nftOwnerships?.length || 0;
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>(defaultPaginationInfo);
    const {data: redeemableNftsResponse} = useGetRedeemableNftOwnerships(fullToken as Token);
    const [
        showMyRewardsDrawer,
        setShowMyRewardsDrawer,
    ] = useState<boolean>(false);
    const start = paginationInfo.offset * paginationInfo.limit;
    let end = start + paginationInfo.limit;
    if (end > count) {
        end = count;
    }
    useEffect(() => {
        // Recalculate pagination & reset the results back to page 1
        onPaginationChange({
            ...paginationInfo,
            offset: 0,
        });
    }, [nftOwnershipsData?.nftOwnerships]);

    function onPaginationChange(newPagination: FrontendPagination) {
        const newPaginationInfo: PaginationInfo = getPaginationInfo({
            limit: newPagination.limit,
            offset: newPagination.offset,
            numberOfAvailableOffsets: 5,
        }, count);

        setPaginationInfo({
            ...newPagination,
            ...newPaginationInfo,
        });
    }

    const [
        isGoFrensLoading,
        setIsGoFrensLoading,
    ] = useState<boolean>(false);

    function createRefreshNftOwnerships(force: boolean): () => Promise<void> {
        return async () => {
            dispatch(incrementLoading());
            setIsGoFrensLoading(true);
            try {
                const res = await new UsersApi(getConfig()).updateNftOwnershipForUserRaw({force: force
                    ? "true"
                    : "false"});
                if (res.raw.status === 200) {
                    window.location.reload();
                }
            } catch (e) {
                dispatch(await addError(e));
            } finally {
                setIsGoFrensLoading(false);
                dispatch(decrementLoading());
            }
        };
    }

    return (
        <>
            {redeemableNftsResponse &&
                <GoVipRewardsInventoryDrawer
                    redeemableNfts={redeemableNftsResponse.nftOwnerships}
                    show={showMyRewardsDrawer}
                    toggle={() => setShowMyRewardsDrawer(false)}
                />
            }

            <div className="collectibles-page">
                <DevourTopNav text="My Orders"/>
                <div className="collectibles-page_container">
                    <div className="gofrens-my-nfts_header">
                        <div className="gofrens-my-nfts_header_main">
                            <div className="gofrens-my-nfts_header_main_top">
                                <div className="gofrens-my-nfts_header_main_top_title">
                                    <h3>My Collectibles</h3>
                                </div>
                                <div className="gofrens-my-nfts_header_main_top_number">
                                    <span>{nftOwnershipsData
                                        ? nftOwnershipsData.nftOwnerships.length
                                        : 0}</span>
                                </div>
                                <div
                                    className="gofrens-my-nfts_header_main_top_btn"
                                    onClick={async() => await createRefreshNftOwnerships(true)()}
                                >
                                    <MdRefresh />
                                    <span>{isGoFrensLoading
                                        ? "Loading..."
                                        : "Refresh"}</span>
                                </div>
                            </div>
                            <div className="gofrens-my-nfts_header_main_description">View all of my POP Badges, GoFriends, and Promotions</div>
                        </div>
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="white-drop-shadow"
                            size={isMobile && !isTablet
                                ? "pill"
                                : "narrow"}
                            onClick={() => setShowMyRewardsDrawer(true)}
                            rightIcon={HiOutlineChevronRight}
                            className="gofrens-my-communities_description_button"
                        >
                    My Rewards
                        </FrameButton>
                    </div>
                    <div className="view-all_card-container">
                        {!nftOwnershipsData && <GoFrensMyNftsSkeleton noOfCards={isDesktop
                            ? 6
                            : !isTablet && isMobile
                                ? 2
                                : 4}/>}

                        {nftOwnershipsData?.nftOwnerships
                            .slice(start, end)
                            .map((ownership) => {
                                return (
                                    <GoFrensNftCard
                                        key={ownership?.id}
                                        ownership={ownership}
                                    />
                                );
                            })}
                    </div>
                    <FramePaginator
                        {...paginationInfo}
                        theme={isOnDarkMode ? "dark" : "light"}
                        onPaginationChange={onPaginationChange}
                        showPaginatorLimit={false}
                    />
                </div>
            </div>
        </>
    );
};
