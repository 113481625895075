import {ReactElement, useEffect, useState} from "react";
import {GuildLeaderboardView, leaderboardViewModeButtonContent} from "@/pages/guilds/GuildsTopLeaderboard";
import {ViewMode} from "@/components/goFrens/GoFrensTopLeaderboard";
import {getDateInEst} from "@/utils/getDateInEst";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import useGetLeaderboardDateRange from "@/hooks/useGetLeaderboardDateRange";
import useGetTopGuildsLeaderboard from "@/hooks/useGetTopGuildsLeaderboard";
import useGetTopGuildLeadersLeaderboard from "@/hooks/useGetTopGuildLeadersLeaderboard";
import FrameOneSelect from "@/components/inputs/FrameOneSelect";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import useThemePreference from "@/hooks/useThemePreference";
import GoFrensTopLeaderboardCommunityTableHeader
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardCommunityTableHeader";
import GoFrensMyCommunityLeaderboardSkeleton
    from "@/components/skeletons/GoFrensPage/GoFrensMyCommunityLeaderboardSkeleton";
import GoFrensTopLeaderboardEmptyState from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardEmptyState";
import GoFrensTopLeaderboardCommunityLeadersEmptyState
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardCommunityLeadersEmptyState";

interface Props<T> {
    isOpen: boolean;
    onClose: () => void;
    renderFunction: (data: T, index: number) => ReactElement;
    initialDateValue: string;
    currentView: GuildLeaderboardView;
    periodViewMode: ViewMode;
}

export default function GuildsTopLeaderboardModal<T>(props: Props<T>): ReactElement {
    const now = getDateInEst();
    const currMonth = now.getMonth() + 1;
    const currYear = now.getFullYear();

    // const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { isOnDarkMode } = useThemePreference();
    // check guild membership
    const [dateValue, setDateValue] = useState<string>(props.initialDateValue);
    const [viewMode, setViewMode] = useState<ViewMode>(props.periodViewMode);
    const {data: dateRange} = useGetLeaderboardDateRange(fullToken, "guild");
    const {data: guildsData, isFetching: isGuildsDataFetching} = useGetTopGuildsLeaderboard(fullToken, `${dateValue},${viewMode}`, props.currentView === GuildLeaderboardView.GUILDS);
    const {data: leadersData, isFetching: isLeadersDataFetching} = useGetTopGuildLeadersLeaderboard(fullToken, `${dateValue},${viewMode}`, props.currentView === GuildLeaderboardView.LEADERS);

    useEffect(() => {
        if (props.isOpen) {
            setDateValue(props.initialDateValue);
            setViewMode(props.periodViewMode);
        }
    }, [props.isOpen]);

    function toggleViewMode(): void {
        setViewMode(viewMode === ViewMode.MONTHLY ? ViewMode.CUMULATIVE : ViewMode.MONTHLY);
    }

    function renderLeaderboardData(): ReactElement {
        const data = props.currentView === GuildLeaderboardView.GUILDS ? guildsData?.guilds : leadersData?.leaders;
        const isDataFetching = props.currentView === GuildLeaderboardView.GUILDS ? isGuildsDataFetching : isLeadersDataFetching;

        if (!data || isDataFetching) {
            return <GoFrensMyCommunityLeaderboardSkeleton rows={5}/>;
        } else if (data.length === 0 && props.currentView === GuildLeaderboardView.GUILDS) {
            return <GoFrensTopLeaderboardEmptyState
                isOnGuildsPage={true}
                isModal={true}
                headerText={"There are no top guilds atm..."}
                contentText={"We are waiting for guilds to be listed on this leaderboard. Start by joining a guild and earning XP to see your guild here!"}
            />;
        } else if (data.length === 0 && props.currentView === GuildLeaderboardView.LEADERS) {
            return <GoFrensTopLeaderboardCommunityLeadersEmptyState
                isOnGuildsPage={true}
                isModal={true}
                headerText="There are no top guild leaders atm..."
                contentText="We are waiting for guild leaders to be listed on this leaderboard. Start by joining a guild and earning XP to see yourself here!"
            />;
        }

        return <>
            <GoFrensTopLeaderboardCommunityTableHeader isModal={true}/>
            {data.map((value: any, index: number) => props.renderFunction(value as T, index))}
        </>;
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="md"
            contentClassName="gofrens-top-leaderboard_modal-leaderboard"
        >
            <FrameAutoPanelHeader
                title={props.currentView === GuildLeaderboardView.GUILDS
                    ? "Top 50 Guilds"
                    : "Top 50 Guild Leaders"}
                toggle={props.onClose}
                showXBottomPanel={true}
                isTitleBold={true}
            />

            <FrameAutoPanelBody>
                <div className="gofrens-top-leaderboard_button-container">
                    <div className="gofrens-top-leaderboard_button-container-item1">
                        <FrameOneSelect
                            className="gofrens-top-leaderboard_top-inputs_dropdown-container_select"
                            disabled={!dateRange || props.currentView === GuildLeaderboardView.GUILDS ? !guildsData : !leadersData}
                            value={dateValue}
                            onChange={(e) => setDateValue(e.target.value)}
                        >
                            <option value={`${currMonth},${currYear}`}>Current Month</option>
                            {dateRange?.dates.map(date => <option
                                value={date.dateParam}
                                key={date.dateParam}>{date.dateValue}</option>)}
                        </FrameOneSelect>
                    </div>

                    <div className="gofrens-top-leaderboard_view-mode">
                        {leaderboardViewModeButtonContent(viewMode, toggleViewMode, isOnDarkMode, false)}
                    </div>
                </div>

                <div className="gofrens-top-leaderboard_modal-leaderboard_container">
                    <div className="gofrens-top-leaderboard_scroll-div">
                        {renderLeaderboardData()}
                    </div>
                </div>
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}