import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore.ts";
import useGetGuildOwnerships from "@/hooks/useGetGuildOwnerships.ts";
import {useEffect, useState} from "react";
import {nftsDefaultPaginationInfo} from "@/pages/gofrens/GoFrensMyNfts.tsx";
import { PaginationInfo } from "@devour/client";
import FramePaginator, {FrontendPagination} from "@/components/paginator/FramePaginator.tsx";
import {getPaginationInfo} from "@/utils/paginationUtils.ts";
import {FiChevronLeft} from "react-icons/fi";
import GoFrensMyNftsSkeleton from "@/components/skeletons/GoFrensPage/GoFrensMyNftsSkeleton.tsx";
import {isDesktop, isTablet} from "react-device-detect";
import GoFrensNftCard from "@/components/goFrens/GoFrensNftCard.tsx";
import Spacer from "@/components/Spacer.tsx";
import GoFrensNftGroupingsSkeleton from "@/components/skeletons/GoFrensPage/GoFrensNftGroupingsSkeleton.tsx";
import GuildCard from "@/pages/guilds/GuildCard.tsx";


export default function MyOtherGuildsViewAll() {
    const navigate = useNavigate();

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: guildOwnerships} = useGetGuildOwnerships(fullToken, true);
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>(nftsDefaultPaginationInfo);

    const count = guildOwnerships?.otherGuilds.length || 0;
    const start = paginationInfo.offset * paginationInfo.limit;
    let end = start + paginationInfo.limit;
    if (end > count) {
        end = count;
    }

    useEffect(() => {
        if (!fullToken) {
            navigate("/guilds");
        }
    }, [fullToken?.id]);

    useEffect(() => {
        // Recalculate pagination & reset the results back to page 1
        onPaginationChange({
            ...paginationInfo,
            offset: 0,
        });
    }, [guildOwnerships?.otherGuilds]);

    function onPaginationChange(newPagination: FrontendPagination) {
        const newPaginationInfo: PaginationInfo = getPaginationInfo({
            limit: newPagination.limit,
            offset: newPagination.offset,
            numberOfAvailableOffsets: 5,
        }, count);

        setPaginationInfo({
            ...newPagination,
            ...newPaginationInfo,
        });
    }
    return (
        <div className="gofrens-search guilds-badges_all">
            <div className="gofrens-search_back" onClick={() => navigate("/guilds")}>
                <FiChevronLeft className="view-all_back_icon"/>
                <p>Go Back</p>
            </div>

            <div className="gofrens-my-communities_description_title">
                <h3 className="gofrens-my-communities_description_title_header">My Other Guilds</h3>
                <p className="gofrens-my-communities_description_title_subheader">
                    Guilds with {isDesktop || isTablet ? "Proof of Play" : "POP"} Badges
                </p>
            </div>
            <div className="guilds-explore_content_grid-view guilds-badges_all_container">
                {!guildOwnerships
                    ? <GoFrensNftGroupingsSkeleton noOfCards={isTablet ? 3 : 4}/>
                    : guildOwnerships.otherGuilds.slice(start, end).map(guild =>
                        <GuildCard key={guild.id} guild={guild} />)
                }
            </div>

            <Spacer />
            {guildOwnerships && <FramePaginator
                {...paginationInfo}
                onPaginationChange={onPaginationChange}
                showPaginatorLimit={false}
            />}
        </div>
    );
}