import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import classNames from "classnames";
import {AccountExperienceTransaction, GetOverwolfXpSubjectEnum, OWGame} from "@devour/client";
import {FiX} from "react-icons/fi";
import FrameButton from "@/components/buttons/FrameButton";
import {AnchorHTMLAttributes, ReactElement, useState} from "react";
import useThemePreference from "@/hooks/useThemePreference";
import {RewardsView} from "@/pages/overwolf/components/OverwolfGoVipRewards";
import FrameOneSwitchInput from "@/components/inputs/FrameOneSwitchInput";
import OverwolfGoVipQuestRewards from "@/pages/overwolf/components/OverwolfGoVipQuestRewards";
import useGetOverwolfGameInfo from "@/hooks/useGetOverwolfGameInfo";
import useGetOverwolfGameRewards from "@/hooks/useGetOverwolfGameRewards";
import useGetUserOverwolfXP from "@/hooks/useGetUserOverwolfXP";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import Skeleton from "react-loading-skeleton";
import useGetOverwolfUserStats from "@/hooks/useGetOverwolfUserStats";
import {isDesktop} from "react-device-detect";
import GoVipOWTimeRewardsModalContent from "@/components/goVip/GoVipOWTimeRewardsModalContent";
import FrameOneModal from "../modals/modalComponents/FrameOneModal";
import FrameModalBody from "../modals/modalComponents/FrameModalBody";
import AssetImage from "@/components/AssetImage";
import {useAsset} from "@/hooks/useAsset";
import {useGetGuilds} from "@/hooks/useGetGuilds";
import {useGetGuildProgress} from "@/hooks/useGetGuildProgress";

interface Props {
    isOpen: boolean;
    questOnly?: boolean;
    onClose: () => void;
    rewards: Array<AccountExperienceTransaction>;
    game: OWGame;
}

export default function GoVipOWDualRewardModal(props: Props) {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { isOnDarkMode } = useThemePreference();
    const { isOnOverwolf } = useOverwolfInterop();
    const [rewardsView, setRewardsView] = useState<RewardsView>(props.questOnly ? RewardsView.QUESTS : RewardsView.TIME_PLAYED);
    const gameInfo = props.game;

    const { data: overwolfGameInfo, isLoading: isOverwolfGameInfoLoading } = useGetOverwolfGameInfo(gameInfo?.gameId);

    const { data: overwolfGameRewards, isLoading: isOverwolfGameRewardsLoading } = useGetOverwolfGameRewards(
        gameInfo?.gameId,
        overwolfGameInfo?.isEventSupported ?? false,
    );
    const {data: bannerImage} = useAsset(overwolfGameInfo?.bannerImageSmall);

    const {
        data: rewardsData,
        isLoading: isRewardsDataInitialLoading,
    } = useGetUserOverwolfXP(fullToken, GetOverwolfXpSubjectEnum.TIMEREWARD, undefined);

    const {data: userStats, refetch: refetchUserStats, isLoading: isUserStatsLoading} = useGetOverwolfUserStats(fullToken, overwolfGameInfo?.gameId);

    const {data: guildsResponse} = useGetGuilds(undefined, undefined, undefined, [gameInfo?.id]);
    const { data: guildProgress, refetch: refetchGuildProgress } = useGetGuildProgress(guildsResponse?.guilds?.[0]?.id, fullToken);

    useOverwolfInterop((ev) => {
        if (ev.type === "ow:game-events-logged") {
            refetchUserStats().then();
        } else if (ev.type === "ow:guild-progress-updated") {
            void refetchGuildProgress();
        }
    });

    const isLoading = isRewardsDataInitialLoading ||
    isOverwolfGameInfoLoading ||
    isOverwolfGameRewardsLoading ||
    isUserStatsLoading;

    const renderSkeleton = () =>
        <div className="overwolf-govip_rewards_quest_list">
            {Array.from({ length: 5 }, (_, i) =>
                <div key={i} className="go-vip-ow_time-reward-card">
                    <div>
                        <p>
                            <Skeleton width={150} />
                        </p>
                        <span>
                            <Skeleton width={110} />
                        </span>
                    </div>
                    <span>
                        <Skeleton width={110} />
                    </span>
                </div>)}
        </div>;
    function renderRewardsContent(): ReactElement {
        return isLoading ? renderSkeleton()
            : <>
                {rewardsView === RewardsView.TIME_PLAYED &&
                    <GoVipOWTimeRewardsModalContent rewards={rewardsData?.transactions || []} />
                }

                {rewardsView === RewardsView.QUESTS &&
                    <OverwolfGoVipQuestRewards
                        forceModalDesign={true}
                        userStats={userStats?.userStats || []}
                        gameId={gameInfo?.gameId}
                        alwaysMobile
                        quests={overwolfGameRewards || []}
                        guildProgress={guildProgress}
                    />
                }

                {!isOnOverwolf && rewardsView === RewardsView.QUESTS && !userStats?.userStats?.length &&
                    <FrameButton<AnchorHTMLAttributes<HTMLAnchorElement>>
                        size="normal"
                        className="go-vip-ow-dual-rewards-modal_body_download-btn"
                        href={"https://www.overwolf.com/app/Devour-DevourPlay"}
                        forwardProps={{
                            target: "_blank",
                            rel: "noopener noreferrer",
                        }}
                    >
                        Download DevourPlay
                    </FrameButton>
                }
            </>;
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName={classNames("go-vip-ow_rewards-modal dual-rewards", {
                authenticated: !!fullToken,
            })}
            size="xs2"
        >
            <div
                style={{
                    backgroundImage: `url(${bannerImage?.url})`,
                }}
                className="go-vip-ow-dual-rewards-modal_bg"
            ></div>
            <div className="go-vip-ow-dual-rewards-modal_header">
                <button className="reset-button go-vip-ow_rewards-modal_header_close">
                    <FiX size={isOnDarkMode ? "2rem" : "1.5rem"} strokeWidth="0.175rem" onClick={props.onClose} />
                </button>
                <div className="go-vip-ow-dual-rewards-modal_header_text">
                    <AssetImage
                        assetId={overwolfGameInfo?.devourGameLogo}
                        alt={overwolfGameInfo?.name}
                        className="go-vip-ow-dual-rewards-modal_logo"
                    />
                    {props.questOnly
                        ? <h1>QUESTS</h1>
                        : <h1>
                            {isDesktop
                                ? "TRACK REWARDS"
                                : <>
                                    TRACK
                                    <br />
                                    REWARDS
                                </>
                            }
                        </h1>
                    }
                </div>

                {!props.questOnly &&
                    <FrameOneSwitchInput<RewardsView>
                        className="go-vip-ow-dual-rewards-modal_header_switch"
                        name="overwolf-rewards-view-toggle"
                        value={rewardsView}
                        onToggle={setRewardsView}
                        options={[
                            {
                                render: "Quests",
                                value: RewardsView.QUESTS,
                            },
                            {
                                render: "Time Played",
                                value: RewardsView.TIME_PLAYED,
                            },
                        ]}
                    />
                }

                {rewardsView === RewardsView.TIME_PLAYED &&
                    <div className="go-vip-ow-dual-rewards-modal_header_time-text">
                        <div className="go-vip-ow-dual-rewards-modal_header_text_description">
                            Earn 10 XP for every hour of gameplay!
                            <br />
                            The more you play, the more XP you collect.
                        </div>

                        <div className="go-vip-ow-dual-rewards-modal_header_text_disclaimer">
                            * Max XP you can earn is 1,000 per week.
                        </div>
                    </div>
                }
            </div>
            {/* <div className="go-vip-ow-dual-rewards-modal_list-shader"></div> */}
            <FrameModalBody className="go-vip-ow-dual-rewards-modal_body go-vip-ow_rewards-modal_body">
                {renderRewardsContent()}
            </FrameModalBody>
        </FrameOneModal>
    );
}
