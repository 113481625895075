import {useParams} from "react-router";
import {useGetChallenge} from "@/hooks/challenges/useGetChallenge";
import {useEffect} from "react";
// import ChallengeQuestsAndPrizes from "@/pages/challenge/ChallengeQuestsAndPrizes";
import useThemePreference from "@/hooks/useThemePreference";
import BrandLandingFooter from "@/components/brands/BrandLandingFooter";
import Spacer from "@/components/Spacer";
import { BrandChallengeCard } from "./challenge/BrandChallengeCard";
import { BrandChallengeHeading } from "./challenge/BrandChallengeHeading";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";

export default function BrandChallengePage() {
    const {id} = useParams<{ id: string }>();
    // const navigate = useNavigate();
    const {data: challenge} = useGetChallenge(id);
    const {isOnDarkMode} = useThemePreference();

    useEffect(() => {
        // TODO: if challenge needs to be rendered on a brand page, navigate to the brand page
    }, [challenge]);

    // Note to developers: Please make the sections modular and reusable in pages/challenge dir so we add to brand page
    return (
        <div className="brand-challenge">
            <BrandChallengeHeading challenge={challenge}/>
            <div className="brand-challenge_content-wrapper">
                <div className="brand-challenge_content">
                    {/* header  */}
                    <BrandChallengeCard challenge={challenge}/>
                    {/* <ChallengeQuestsAndPrizes />*/}
                    <Spacer />
                    <BrandLandingFooter darkMode={isOnDarkMode}/>
                </div>
            </div>
        </div>
    );
}