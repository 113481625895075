/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TopGuildLeaderLeaderboard
 */
export interface TopGuildLeaderLeaderboard {
    /**
     * 
     * @type {string}
     * @memberof TopGuildLeaderLeaderboard
     */
    guildIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof TopGuildLeaderLeaderboard
     */
    guildName?: string;
    /**
     * 
     * @type {number}
     * @memberof TopGuildLeaderLeaderboard
     */
    totalPoints: number;
    /**
     * 
     * @type {string}
     * @memberof TopGuildLeaderLeaderboard
     */
    nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof TopGuildLeaderLeaderboard
     */
    profilePicture?: string;
    /**
     * 
     * @type {number}
     * @memberof TopGuildLeaderLeaderboard
     */
    rank: number;
    /**
     * 
     * @type {string}
     * @memberof TopGuildLeaderLeaderboard
     */
    id: string;
}

export function TopGuildLeaderLeaderboardFromJSON(json: any): TopGuildLeaderLeaderboard {
    return TopGuildLeaderLeaderboardFromJSONTyped(json, false);
}

export function TopGuildLeaderLeaderboardFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopGuildLeaderLeaderboard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guildIcon': !exists(json, 'guildIcon') ? undefined : json['guildIcon'],
        'guildName': !exists(json, 'guildName') ? undefined : json['guildName'],
        'totalPoints': json['totalPoints'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'profilePicture': !exists(json, 'profilePicture') ? undefined : json['profilePicture'],
        'rank': json['rank'],
        'id': json['id'],
    };
}

export function TopGuildLeaderLeaderboardToJSON(value?: TopGuildLeaderLeaderboard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guildIcon': value.guildIcon,
        'guildName': value.guildName,
        'totalPoints': value.totalPoints,
        'nickname': value.nickname,
        'profilePicture': value.profilePicture,
        'rank': value.rank,
        'id': value.id,
    };
}


