/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TopGuildLeaderboard,
    TopGuildLeaderboardFromJSON,
    TopGuildLeaderboardFromJSONTyped,
    TopGuildLeaderboardToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetTopGuildsLeaderboardResponse
 */
export interface GetTopGuildsLeaderboardResponse {
    /**
     * 
     * @type {Array<TopGuildLeaderboard>}
     * @memberof GetTopGuildsLeaderboardResponse
     */
    guilds: Array<TopGuildLeaderboard>;
}

export function GetTopGuildsLeaderboardResponseFromJSON(json: any): GetTopGuildsLeaderboardResponse {
    return GetTopGuildsLeaderboardResponseFromJSONTyped(json, false);
}

export function GetTopGuildsLeaderboardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTopGuildsLeaderboardResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guilds': ((json['guilds'] as Array<any>).map(TopGuildLeaderboardFromJSON)),
    };
}

export function GetTopGuildsLeaderboardResponseToJSON(value?: GetTopGuildsLeaderboardResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guilds': ((value.guilds as Array<any>).map(TopGuildLeaderboardToJSON)),
    };
}


