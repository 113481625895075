import {useQuery} from "@tanstack/react-query";
import {useSelector} from "react-redux";
import {ChallengesApi, GetChallengesResponse, GetChallengesSort, SortOrder} from "@devour/client";
import {IStore} from "../redux/defaultStore";
import getConfig from "../utils/getConfig";

interface ChallengesParams {
    limit: number;
    offset: number;
    userId?: string;
    search?: string;
    sort?: GetChallengesSort;
    order?: SortOrder;
}

async function fetchChallenges(
    { limit, offset, userId, search, sort, order }: ChallengesParams,
    fullToken,
): Promise<GetChallengesResponse> {
    return await new ChallengesApi(getConfig(fullToken)).getChallenges({
        limit,
        offset,
        userId,
        search,
        sort: sort || GetChallengesSort.CREATEDAT,
        order: order || SortOrder.Desc,
    });
}

function challengesQuery(
    { limit, offset, userId, search, sort, order }: ChallengesParams,
    fullToken,
) {
    return {
        queryKey: ["challenges", limit, offset, userId, search, sort, order],
        queryFn: () => fetchChallenges({ limit,
            offset,
            userId,
            search,
            sort,
            order }, fullToken),
        staleTime: 60000,
        refetchOnWindowFocus: false,
    } as const;
}

export function useGetChallenges({
    limit,
    offset,
    userId,
    search,
    sort,
    order,
}: ChallengesParams) {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    return useQuery(challengesQuery({ limit,
        offset,
        userId,
        search,
        sort,
        order }, fullToken));
}