import {ButtonHTMLAttributes, ReactElement} from "react";
import {isDesktop} from "react-device-detect";
import FrameButton from "@/components/buttons/FrameButton";
import GoFrensTopLeaderboardEmptyStateRows
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardEmptyStateRows";
import useThemePreference from "@/hooks/useThemePreference";
import {useNavigate} from "react-router-dom";

interface Props {
    isModal?: boolean;
    isOnGuildsPage?: boolean;
    headerText?: string;
    contentText?: string;
    button?: ReactElement;
}

function GoFrensTopLeaderboardEmptyState(props: Props): ReactElement {
    const {isOnGuildsPage} = props;

    return (
        <div className="gofrens-top-leaderboard_empty-state">
            <div className="gofrens-top-leaderboard_empty-state_left" style={{width: props?.isModal
                ? "100%"
                : ""}}>
                <div className="gofrens-top-leaderboard_empty-state_left_graphic-container">
                    <img
                        src={import.meta.env.VITE_CDN_URL + "/images/gofrens-empty-state-medal.webp"}
                        alt="Empty state logo"
                        className="gofrens-top-leaderboard_empty-state_left_graphic"
                    />
                </div>
                <div className="gofrens-top-leaderboard_empty-state_left_description">
                    <h3>{props.headerText}</h3>
                    <p>{props.contentText}</p>
                    {isDesktop && props.button}
                </div>
            </div>
            {!props.isModal && <GoFrensTopLeaderboardEmptyStateRows leaderboardType={isOnGuildsPage ? "Guild" : "Community"}/>}
        </div>
    );
}

export default GoFrensTopLeaderboardEmptyState;
