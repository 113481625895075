/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OWRewardType,
    OWRewardTypeFromJSON,
    OWRewardTypeFromJSONTyped,
    OWRewardTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface OWRewardMilestone
 */
export interface OWRewardMilestone {
    /**
     * 
     * @type {string}
     * @memberof OWRewardMilestone
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof OWRewardMilestone
     */
    target: number;
    /**
     * 
     * @type {OWRewardType}
     * @memberof OWRewardMilestone
     */
    rewardType: OWRewardType;
    /**
     * 
     * @type {number}
     * @memberof OWRewardMilestone
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof OWRewardMilestone
     */
    nftTrackerId?: string;
}

export function OWRewardMilestoneFromJSON(json: any): OWRewardMilestone {
    return OWRewardMilestoneFromJSONTyped(json, false);
}

export function OWRewardMilestoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWRewardMilestone {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'target': json['target'],
        'rewardType': OWRewardTypeFromJSON(json['rewardType']),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'nftTrackerId': !exists(json, 'nftTrackerId') ? undefined : json['nftTrackerId'],
    };
}

export function OWRewardMilestoneToJSON(value?: OWRewardMilestone | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'target': value.target,
        'rewardType': OWRewardTypeToJSON(value.rewardType),
        'amount': value.amount,
        'nftTrackerId': value.nftTrackerId,
    };
}


