import FrameButton from "@/components/buttons/FrameButton";
import DevourTopNavUserData from "@/components/DevourTopNavUserData";
import { IStore } from "@/redux/defaultStore";
import { ChallengeInfoResponse } from "@devour/client";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RestaurantSearchAddressButton from "../restaurants/components/RestaurantSearchAddressButton";
import { toggleLoginOpen } from "@/redux/meta/metaActions";
import AssetImage from "@/components/AssetImage";
import { formatChallengeDateRange } from "@/utils/formatChallengeDateRange";
import classNames from "classnames";
import { isColorDarkOrLight } from "@/utils/isColorDarkOrLight";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router";

interface Props {
    challenge: ChallengeInfoResponse | undefined;
}

export const BrandChallengeHeading: FC<Props> = ({ challenge }) => {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const navigate = useNavigate();
    const { isOnOverwolf } = useOverwolfInterop();
    return (
        <>
            {!isOnOverwolf && <div className="brand-challenge_top-bar">
                {!fullToken
                    ? <>
                        <img
                            className="brand-challenge_top-bar_logo"
                            src={`${import.meta.env.VITE_CDN_URL}/images/DevourGo-logo-white.png`}
                            alt=""
                        />
                        <FrameButton
                            className="brand-challenge_top-bar_login-btn"
                            onClick={() => {
                                dispatch(toggleLoginOpen(true));
                            }}
                            color="white-outline"
                            size="narrow"
                        >
                            Login
                        </FrameButton>
                    </>
                    : <>
                        <img
                            className="brand-challenge_top-bar_logo"
                            src={`${import.meta.env.VITE_CDN_URL}/images/DevourGo-logo-white.png`}
                            alt=""
                        />
                        <DevourTopNavUserData
                            onShowSignInToast={() => {}}
                            dropdownAnchorElement=".brand-challenge_top-bar"
                            avatarOnly
                            hideWalletOnMobile={true}
                        />
                    </>
                }
            </div>}
            {challenge &&
                <div
                    className="brand-challenge_header"
                    style={{
                        backgroundColor: challenge?.brandColor,
                    }}
                >
                    <div className="brand-challenge_header_brand">
                        {isOnOverwolf && <FaArrowLeftLong className={isColorDarkOrLight(challenge.brandColor)} onClick={() => navigate("/overwolf-go-vip")} />}
                        {challenge?.brandLogo ? <AssetImage
                            className="brand-challenge_header_logo"
                            assetId={challenge?.brandLogo as any}
                            alt="brand-logo"
                        /> : <div/>}
                    </div>
                    <span
                        className={classNames(
                            "brand-challenge_header_duration",
                            isColorDarkOrLight(challenge.brandColor),
                        )}
                    >
                        Duration {formatChallengeDateRange(challenge?.start, challenge?.end)}
                    </span>
                </div>
            }
        </>
    );
};
