/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TopGuildLeaderLeaderboard,
    TopGuildLeaderLeaderboardFromJSON,
    TopGuildLeaderLeaderboardFromJSONTyped,
    TopGuildLeaderLeaderboardToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetCurrentGuildTopMembersResponse
 */
export interface GetCurrentGuildTopMembersResponse {
    /**
     * 
     * @type {Array<TopGuildLeaderLeaderboard>}
     * @memberof GetCurrentGuildTopMembersResponse
     */
    members: Array<TopGuildLeaderLeaderboard>;
}

export function GetCurrentGuildTopMembersResponseFromJSON(json: any): GetCurrentGuildTopMembersResponse {
    return GetCurrentGuildTopMembersResponseFromJSONTyped(json, false);
}

export function GetCurrentGuildTopMembersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCurrentGuildTopMembersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'members': ((json['members'] as Array<any>).map(TopGuildLeaderLeaderboardFromJSON)),
    };
}

export function GetCurrentGuildTopMembersResponseToJSON(value?: GetCurrentGuildTopMembersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'members': ((value.members as Array<any>).map(TopGuildLeaderLeaderboardToJSON)),
    };
}


