import {
    GetTopGuildsLeaderboardResponse,
    GuildsApi,
    Token,
} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useQuery} from "@tanstack/react-query";

async function fetchTopGuildsLeaderboard(leaderboardTimeParam: string): Promise<GetTopGuildsLeaderboardResponse> {
    return await new GuildsApi(getConfig()).getTopGuildsLeaderboard({leaderboardTimeParam});
}

function useGetTopGuildsLeaderboardQuery(fullToken: Token, leaderboardTimeParam: string, enabled: boolean) {
    return {
        queryKey: [
            "top-guilds-leaderboard",
            leaderboardTimeParam,
        ],
        queryFn: () => fetchTopGuildsLeaderboard(leaderboardTimeParam),
        staleTime: 30 * 60000,
        enabled: !!leaderboardTimeParam && !!fullToken && enabled,
    } as const;
}

export default function useGetTopGuildsLeaderboard(fullToken: Token, leaderboardTimeParam: string, enabled: boolean) {
    return useQuery(useGetTopGuildsLeaderboardQuery(fullToken, leaderboardTimeParam, enabled));
}