import {ReactElement, ReactNode, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import {IStore} from "@/redux/defaultStore";
import {decrementModalCount, incrementModalCount} from "@/redux/meta/metaActions";
import variables from "../../../style/variables/scssVariables";
import {getScrollbarWidth, isDocumentOverflow} from "@/utils/isDocumentOverflow";

// Get the modal timing variable from the css exports
const modalTiming: number = parseInt(variables["frame-one-modal-transition-timing"]);
const initialModalzIndex: number = 50;

interface Props {
    isOpen: boolean;
    children: ReactNode;
    toggle?: () => void;
    size?: "xs" | "xs1" | "xs2" | "sm" | "sm2" | "md" | "lg" | "xl" | "max";
    contentClassName?: string;
    containerClassName?: string;
    disableOverlayDismiss?: boolean;
}

function FrameOneModal(props: Props): ReactElement {
    const activeModals = useSelector((store: IStore) => store.metaStore.activeModals);
    const dispatch = useDispatch();
    const {isOpen} = props;
    const lockRef = useRef<boolean>(false);
    const [
        zIndex,
        setZIndex,
    ] = useState(initialModalzIndex); // Interim solution for nested modals to handle their render order without requiring config for each implementation.
    const [
        intermediateController,
        setIntermediateController,
    ] = useState(undefined); // used as an intermediary to help control the animations & visual state of the modal.
    const [
        shader,
        setShader,
    ] = useState(undefined); // helps control the background color of the overlay.
    const [
        forceCloser,
        setForceCloser,
    ] = useState(true); // used on a delay to let the animation play out when the modal closes before the "display" is set back to "none" to prevent cutting the animation short.

    // Ensure the modal count is decremented when the component is unmounted (either while the modal is open or locked in the open state).
    useEffect(() => {
        return () => {
            if (lockRef.current) {
                lockRef.current = false;
                dispatch(decrementModalCount());
                setShader(false);
            }
        };
    }, []);

    useEffect(() => {
        if (isOpen && !lockRef.current) {
            lockRef.current = true;
            setIntermediateController(isOpen);
            setForceCloser(false);
            dispatch(incrementModalCount());
        } else if (isOpen && lockRef.current) {
            setZIndex(initialModalzIndex + activeModals);
        } else if (isOpen === false && lockRef.current) {
            lockRef.current = false;
            dispatch(decrementModalCount());
            setShader(false);
        }
    }, [isOpen, lockRef.current]);

    // This cleanup function is to ensure that the modal count is decremented when the component is unmounted before isOpen turns false.
    useEffect(() => {
        function turnOnShader(): void {
            setShader(true);
        }

        function turnOnForceCloser(): void {
            setForceCloser(true);
        }

        if (intermediateController) {
            setTimeout(() => {
                turnOnShader();
            }, 100);
        } else if (intermediateController === false) {
            setTimeout(() => {
                turnOnForceCloser();
            }, modalTiming);
        }
    }, [intermediateController]);

    useEffect(() => {
        function turnOffIntermediate(): void {
            setIntermediateController(false);
        }

        if (shader === false) {
            setTimeout(() => {
                turnOffIntermediate();
            }, 100);
        }
    }, [shader]);

    useEffect(() => {

        /*
         * Disables all scrolling until there's no active modals.
         * if you're not using some kind of toggle to close your modal
         * which handles the modal count, you need to manually decrement
         * via props.dispatch.
         */

        if (activeModals > 0) {
            document.body.style.overflow = "hidden";
            if (isDocumentOverflow() && getScrollbarWidth() > 0) {
                document.body.style.paddingRight = "1rem"; // only apply the padding if there's a scrollbar
            }
        } else {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0";
        }

    }, [activeModals]);


    function toggleHelper(): void {
        if (props.toggle) {
            props.toggle();
        }
    }

    if (forceCloser) {
        return null;
    }

    return (
        <div
            style={{zIndex}}
            className={classNames("frame-one-modal-container", {
                "frame-one-modal-container_closed": !intermediateController && forceCloser,
                "frame-one-modal-container_open": intermediateController,
                "frame-one-modal-container_shader": shader,
                [props.containerClassName]: props.containerClassName,

            })}
        >
            <div className="frame-one-modal-container_inner">
                <div className="frame-one-modal-container_inner_spacer"/>

                <div
                    className={classNames("frame-one-modal", "frame-one-modal-container_inner_content", `frame-one-modal-container_inner_content_size-${props.size}`, props.contentClassName, {
                        "frame-one-modal-container_inner_content_closed": !shader,
                        "frame-one-modal-container_inner_content_open": shader,
                    })}
                >
                    {props.children}
                </div>

                <div className="frame-one-modal-container_inner_spacer"/>

                <div
                    onClick={props.disableOverlayDismiss
                        ? null
                        : toggleHelper}
                    className="frame-one-modal-container_inner_clicker"
                />
            </div>
        </div>
    );
}

FrameOneModal.defaultProps = {
    size: "xs",
} as Props;

export default FrameOneModal;
