import getConfig from "@/utils/getConfig";
import {useQuery} from "@tanstack/react-query";
import {GuildsApi, GetCurrentGuildTopMembersResponse, Token} from "@devour/client";

async function fetchCurrentGuildTopMembers(leaderboardTimeParam: string): Promise<GetCurrentGuildTopMembersResponse> {
    return await new GuildsApi(getConfig()).getCurrentGuildTopMembers({leaderboardTimeParam});
}

function useGetCurrentGuildTopMembersQuery(fullToken: Token, leaderboardTimeParam: string, enabled: boolean) {
    return {
        queryKey: [
            "current-guild-top-members",
            leaderboardTimeParam,
        ],
        queryFn: () => fetchCurrentGuildTopMembers(leaderboardTimeParam),
        staleTime: 30 * 60000,
        enabled: !!leaderboardTimeParam && !!fullToken && enabled,
    } as const;
}

export default function useGetCurrentGuildTopMembers(fullToken: Token, leaderboardTimeParam: string, enabled: boolean) {
    return useQuery(useGetCurrentGuildTopMembersQuery(fullToken, leaderboardTimeParam, enabled));
}