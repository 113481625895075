import { useGetChallenges } from "@/hooks/useGetChallenges";
import Skeleton from "react-loading-skeleton";
import { OverwolfGoVipChallengeCard } from "./OverwolfGoVipChallengesCard";
import DragScroll from "@/components/DragScroll";
import { isMobile } from "react-device-detect";
import { IStore } from "@/redux/defaultStore";
import { useSelector } from "react-redux";

export const OverwolfGoVipChallenges = () => {
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser?.user);
    const { data: challengesData, isFetching: isFetchingChallenges, refetch } = useGetChallenges({ limit: 6,
        offset: 0,
        userId: currentUser?.id });

    if (!isFetchingChallenges && !challengesData) {
        return null;
    }

    return (
        <div style={{position: "relative"}}>
            <DragScroll className="overwolf-go-vip-challenges">
                {isFetchingChallenges
                    ? Array.from({ length: 2 }, (_, i) =>
                        <Skeleton width={isMobile ? "18.75rem" : "24.6rem"} height={isMobile ? "13.0rem" : "13.25rem"} key={i} borderRadius="1rem" />)
                    : challengesData?.challenges.map((challenge) =>
                        <OverwolfGoVipChallengeCard onChallengeAccepted={() => refetch()} key={challenge.id} challenge={challenge} />)}
            </DragScroll>
        </div>
    );
};
