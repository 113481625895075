import {ReactElement, useEffect, useState} from "react";
import {ViewMode} from "@/components/goFrens/GoFrensTopLeaderboard";
import {getDateInEst} from "@/utils/getDateInEst";
import useGetCurrentGuildTopMembers from "@/hooks/useGetCurrentGuildTopMembers";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import GoFrensMyCommunityLeaderboardSkeleton
    from "@/components/skeletons/GoFrensPage/GoFrensMyCommunityLeaderboardSkeleton";
import GuildMembersLeaderboard from "@/pages/guilds/GuildMembersLeaderboard";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";
import {leaderboardViewModeButtonContent} from "@/pages/guilds/GuildsTopLeaderboard";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import FrameOneSelect from "@/components/inputs/FrameOneSelect";
import useGetLeaderboardDateRange from "@/hooks/useGetLeaderboardDateRange";
import useThemePreference from "@/hooks/useThemePreference";


interface Props {
    isOpen: boolean;
    toggle: () => void;
    initialDateValue: string;
}

export default function GuildTopMembersLeaderboardModal(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { isOnDarkMode } = useThemePreference();

    const now = getDateInEst();
    const currentMonth = `${now.getMonth() + 1},${now.getFullYear()}`;
    const [dateValue, setDateValue] = useState<string>(props.initialDateValue);
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.MONTHLY);
    const {data: topMembersData, isFetching: isTopMembersDataFetching} = useGetCurrentGuildTopMembers(fullToken, `${dateValue},${viewMode}`, true);
    const {data: dateRange} = useGetLeaderboardDateRange(fullToken, "guild");
    const topMembers = topMembersData?.members.filter(member => member.totalPoints > 0);

    useEffect(() => {
        if (props.isOpen) {
            setViewMode(ViewMode.MONTHLY);
        }
    }, [props.isOpen]);

    function renderLeaderboardData(): ReactElement {
        if (!topMembersData || isTopMembersDataFetching) {
            return <GoFrensMyCommunityLeaderboardSkeleton rows={5}/>;
        }

        return <GuildMembersLeaderboard
            isCurrentAllTime={viewMode === ViewMode.CUMULATIVE && dateValue === currentMonth}
            isModal={true}
            members={topMembers}
        />;
    }

    function toggleViewMode(): void {
        setViewMode(viewMode === ViewMode.MONTHLY ? ViewMode.CUMULATIVE : ViewMode.MONTHLY);
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.toggle}
            size="md"
            contentClassName="gofrens-top-leaderboard_modal-leaderboard"
        >
            <FrameAutoPanelHeader
                title="Top 50 Members"
                toggle={props.toggle}
                showXBottomPanel={true}
                isTitleBold={true}
            />

            <FrameAutoPanelBody>
                <div className="gofrens-top-leaderboard_button-container">
                    <div className="gofrens-top-leaderboard_button-container-item1">
                        <FrameOneSelect
                            className="gofrens-top-leaderboard_top-inputs_dropdown-container_select"
                            disabled={!dateRange && !topMembersData}
                            value={dateValue}
                            onChange={(e) => setDateValue(e.target.value)}
                        >
                            <option value={currentMonth}>Current Month</option>
                            {dateRange?.dates.map(date => <option
                                value={date.dateParam}
                                key={date.dateParam}>{date.dateValue}</option>)}
                        </FrameOneSelect>
                    </div>

                    <div className="gofrens-top-leaderboard_view-mode">
                        {leaderboardViewModeButtonContent(viewMode, toggleViewMode, isOnDarkMode, false)}
                    </div>
                </div>

                <div className="gofrens-top-leaderboard_modal-leaderboard_container">
                    <div className="gofrens-top-leaderboard_scroll-div">
                        {renderLeaderboardData()}
                    </div>
                </div>
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}