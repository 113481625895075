import {ButtonHTMLAttributes, ReactElement} from "react";
import {isDesktop} from "react-device-detect";
import FrameButton from "@/components/buttons/FrameButton";
import GoFrensTopLeaderboardEmptyStateRows
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardEmptyStateRows";
import useThemePreference from "@/hooks/useThemePreference";
import {useNavigate} from "react-router-dom";

interface Props {
    isModal?: boolean;
    isOnGuildsPage?: boolean;
    headerText?: string;
    contentText?: string;
}

function GoFrensTopLeaderboardEmptyState(props: Props): ReactElement {
    const navigate = useNavigate();
    const {isOnDarkMode} = useThemePreference();

    return (
        <div className="gofrens-top-leaderboard_empty-state">
            <div className="gofrens-top-leaderboard_empty-state_left" style={{width: props?.isModal
                ? "100%"
                : ""}}>
                <div className="gofrens-top-leaderboard_empty-state_left_graphic-container">
                    <img
                        src={import.meta.env.VITE_CDN_URL + "/images/gofrens-empty-state-trophy.webp"}
                        alt="Empty state logo"
                        className="gofrens-top-leaderboard_empty-state_left_graphic"
                    />
                </div>
                <div className="gofrens-top-leaderboard_empty-state_left_description">
                    <h3>{props.headerText}</h3>
                    <p>{props.contentText}</p>
                    {isDesktop && !props.isOnGuildsPage &&
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            size="normal"
                            color={isOnDarkMode
                                ? "purple"
                                : "purple-blue-gradient"}
                            onClick={() => navigate("/go-vip-dashboard")}
                        >
                                Start Earning XP
                        </FrameButton>
                    }
                </div>
            </div>
            {!props.isModal && <GoFrensTopLeaderboardEmptyStateRows leaderboardType={props.isOnGuildsPage ? "Guild" : "Community"}/>}
        </div>
    );
}

export default GoFrensTopLeaderboardEmptyState;
