import {ReactNode, useEffect} from "react";
import {useDispatch} from "react-redux";
import {decrementModalCount, incrementModalCount, toggleHideBottomNav} from "@/redux/meta/metaActions";
import classNames from "classnames";

interface DriftDrawerProps {
    isOpen: boolean;
    toggle: () => void;
    children?: ReactNode;
    className?: string;
    width?: string;
}

export default function DriftDrawer(props: DriftDrawerProps) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.isOpen) {
            dispatch(incrementModalCount());
            dispatch(toggleHideBottomNav(true));
        } else {
            dispatch(toggleHideBottomNav(false));
        }

        return () => {
            dispatch(toggleHideBottomNav(false));
            dispatch(decrementModalCount());
        };
    }, [props.isOpen]);

    return (
        <>
            <div
                className={classNames("drift-drawer_overlay", {
                    "is-active": props.isOpen,
                })}
                onClick={props.toggle}
            />
            <div
                className={classNames("drift-drawer", props.className, {
                    "is-active": props.isOpen,
                })}
                style={{
                    width: props.width || "100vw",
                    right: `-${props.width || "100vw"}`,
                }}
            >
                {props.children}
            </div>
        </>
    );
}