
import {GetLeaderboardsDateRangeResponse, GoFrensApi, Token} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useQuery} from "@tanstack/react-query";

async function fetchLeaderboardDateRange(leaderboardType: string): Promise<GetLeaderboardsDateRangeResponse> {
    return await new GoFrensApi(getConfig()).getLeaderboardsDateRange({
        leaderboardType,
    });
}

function useGetLeaderboardDateRangeQuery(fullToken: Token, leaderboardType: string) {
    return {
        queryKey: [
            "leaderboard-date-range",
            leaderboardType,
        ],
        queryFn: () => fetchLeaderboardDateRange(leaderboardType),
        staleTime: 24 * 60 * 60000,
        enabled: !!leaderboardType && !!fullToken,
    } as const;
}

export default function useGetLeaderboardDateRange(fullToken: Token, leaderboardType: string) {
    return useQuery(useGetLeaderboardDateRangeQuery(fullToken, leaderboardType));
}