import AssetImage from "@/components/AssetImage";
import FrameButton from "@/components/buttons/FrameButton";
import { useAsset } from "@/hooks/useAsset";
import { IStore } from "@/redux/defaultStore";
import { addError, toggleLoginOpen } from "@/redux/meta/metaActions";
import { formatChallengeDateRange } from "@/utils/formatChallengeDateRange";
import getConfig from "@/utils/getConfig";
import { isColorDarkOrLight } from "@/utils/isColorDarkOrLight";
import { ChallengeResponseWithAcceptance, ChallengesApi, GetChallengesSort, SortOrder } from "@devour/client";
import { QueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

interface Props {
    challenge: ChallengeResponseWithAcceptance;
    onChallengeAccepted?: () => void;
}

export const OverwolfGoVipChallengeCard: FC<Props> = ({ challenge, onChallengeAccepted }) => {
    const { data } = useAsset((isMobile ? challenge.backgroundImageSmall : challenge.backgroundImageLarge) as any);
    const [currentDate, setCurrentDate] = useState(new Date());
    const dispatch = useDispatch();
    const {fullToken} = useSelector((store: IStore) => store.authStore);
    const [isAccepting, setIsAccepting] = useState(false);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser?.user);
    const navigate = useNavigate();
    const currentTimeIntervalKey = useRef(null);
    const challengeReward = useMemo(() => {
        if (challenge.rewards.length > 0) {
            return challenge.rewards[0];
        }
        return null;
    }, [challenge]);

    const isChallengeEnded = useMemo(() => {
        return currentDate.getTime() > challenge.end;
    }, [challenge, currentDate]);

    const getChallengeLabel = () => {
        if (isChallengeEnded) {
            return "View";
        }
        if (challenge.isAccepted) {
            return "Track";
        }
        return "Accept";
    };

    const acceptChallenge = async () => {
        setIsAccepting(true);
        try {
            await new ChallengesApi(getConfig(fullToken)).acceptChallenge({
                id: challenge.id,
                acceptChallengeBody: {
                    userId: currentUser.id,
                },
            });
            if (onChallengeAccepted) {
                onChallengeAccepted();
            }
        } catch (err) {
            dispatch(await addError(err));
        }
        setIsAccepting(false);

    };

    const handleChallengeClick = () => {
        if (isChallengeEnded || challenge.isAccepted) {
            navigate(`/challenges/${challenge.id}`, {
                replace: true,
            });
        } else {
            if (!fullToken) {
                dispatch(toggleLoginOpen(true));
                return;
            }
            acceptChallenge();
        }
    };

    useEffect(() => {
        currentTimeIntervalKey.current = setInterval(() => {
            setCurrentDate(new Date());
            if (new Date().getTime() > challenge.end) {
                clearInterval(currentTimeIntervalKey.current);
            }
        }, 6000);
        return () => {
            if (currentTimeIntervalKey.current) {
                clearInterval(currentTimeIntervalKey.current);
            }
        };
    }, [challenge]);

    return (
        <div
            className="overwolf-go-vip-challenges_card"
            style={{
                backgroundImage: `url(${
                    data?.cdnUrl ?? ""
                })`,
            }}
        >
            <div className="overwolf-go-vip-challenges_card_shader"></div>
            <div className="overwolf-go-vip-challenges_card_heading">
                <div className="overwolf-go-vip-challenges_card_heading_info">
                    <AssetImage assetId={challenge.brandLogo as any} alt="brand-logo" />
                    <h5>CHALLENGE</h5>
                    <span>{formatChallengeDateRange(challenge.start, challenge.end)}</span>
                </div>
                <FrameButton
                    size="narrow"
                    color="purple"
                    showSpinnerWithoutGlobalLoading={isAccepting}
                    onClick={handleChallengeClick}
                    forwardProps={{
                        style: {
                            backgroundColor: challenge.brandColor,
                        },
                    }}
                    className={classNames("overwolf-go-vip-challenges_card_heading_action", isColorDarkOrLight(challenge.brandColor))}
                >
                    {getChallengeLabel()}
                </FrameButton>
            </div>
            {challengeReward &&
                <div className="overwolf-go-vip-challenges_card_reward">
                    <AssetImage assetId={challengeReward.image} alt="challenge-reward" />
                    <div className="overwolf-go-vip-challenges_card_reward_info">
                        <h5>{challengeReward.name}</h5>
                        <span>{challengeReward.description}</span>
                    </div>
                </div>
            }
        </div>
    );
};
