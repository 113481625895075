/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    BusinessServiceAvailability,
    BusinessServiceAvailabilityFromJSON,
    BusinessServiceAvailabilityFromJSONTyped,
    BusinessServiceAvailabilityToJSON,
    MenuCustomization,
    MenuCustomizationFromJSON,
    MenuCustomizationFromJSONTyped,
    MenuCustomizationToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a single item available for customization.
 * @export
 * @interface MenuCustomizationItem
 */
export interface MenuCustomizationItem {
    /**
     * 
     * @type {string}
     * @memberof MenuCustomizationItem
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomizationItem
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomizationItem
     */
    updatedAt: number;
    /**
     * Past IDs for items that may not be current. Used to find old items for validation.
     * @type {Array<string>}
     * @memberof MenuCustomizationItem
     */
    otherIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MenuCustomizationItem
     */
    menuCustomization: string;
    /**
     * 
     * @type {string}
     * @memberof MenuCustomizationItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MenuCustomizationItem
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomizationItem
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomizationItem
     */
    taxRate: number;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomizationItem
     */
    minAllowed?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomizationItem
     */
    maxAllowed?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MenuCustomizationItem
     */
    isAlcohol?: boolean;
    /**
     * Dynamic check for final item eligibility after final validations such as NFT ownership.
     * @type {boolean}
     * @memberof MenuCustomizationItem
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {Array<BusinessServiceAvailability>}
     * @memberof MenuCustomizationItem
     */
    serviceAvailabilities?: Array<BusinessServiceAvailability>;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomizationItem
     */
    defaultQuantity?: number;
    /**
     * Sort order for this on the frontend
     * @type {number}
     * @memberof MenuCustomizationItem
     */
    sortOrder?: number;
    /**
     * 
     * @type {Array<MenuCustomization>}
     * @memberof MenuCustomizationItem
     */
    customizations?: Array<MenuCustomization>;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomizationItem
     */
    caloriesBase?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomizationItem
     */
    caloriesMax?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomizationItem
     */
    oloId?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomizationItem
     */
    oloChainId?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuCustomizationItem
     */
    chowlyId?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuCustomizationItem
     */
    chowlyExternalId?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuCustomizationItem
     */
    itsaCheckmateId?: string;
}

export function MenuCustomizationItemFromJSON(json: any): MenuCustomizationItem {
    return MenuCustomizationItemFromJSONTyped(json, false);
}

export function MenuCustomizationItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuCustomizationItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'otherIds': !exists(json, 'otherIds') ? undefined : json['otherIds'],
        'menuCustomization': json['menuCustomization'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'price': json['price'],
        'taxRate': json['taxRate'],
        'minAllowed': !exists(json, 'minAllowed') ? undefined : json['minAllowed'],
        'maxAllowed': !exists(json, 'maxAllowed') ? undefined : json['maxAllowed'],
        'isAlcohol': !exists(json, 'isAlcohol') ? undefined : json['isAlcohol'],
        'isEnabled': !exists(json, 'isEnabled') ? undefined : json['isEnabled'],
        'serviceAvailabilities': !exists(json, 'serviceAvailabilities') ? undefined : ((json['serviceAvailabilities'] as Array<any>).map(BusinessServiceAvailabilityFromJSON)),
        'defaultQuantity': !exists(json, 'defaultQuantity') ? undefined : json['defaultQuantity'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'customizations': !exists(json, 'customizations') ? undefined : ((json['customizations'] as Array<any>).map(MenuCustomizationFromJSON)),
        'caloriesBase': !exists(json, 'caloriesBase') ? undefined : json['caloriesBase'],
        'caloriesMax': !exists(json, 'caloriesMax') ? undefined : json['caloriesMax'],
        'oloId': !exists(json, 'oloId') ? undefined : json['oloId'],
        'oloChainId': !exists(json, 'oloChainId') ? undefined : json['oloChainId'],
        'chowlyId': !exists(json, 'chowlyId') ? undefined : json['chowlyId'],
        'chowlyExternalId': !exists(json, 'chowlyExternalId') ? undefined : json['chowlyExternalId'],
        'itsaCheckmateId': !exists(json, 'itsaCheckmateId') ? undefined : json['itsaCheckmateId'],
    };
}

export function MenuCustomizationItemToJSON(value?: MenuCustomizationItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'otherIds': value.otherIds,
        'menuCustomization': value.menuCustomization,
        'name': value.name,
        'description': value.description,
        'price': value.price,
        'taxRate': value.taxRate,
        'minAllowed': value.minAllowed,
        'maxAllowed': value.maxAllowed,
        'isAlcohol': value.isAlcohol,
        'isEnabled': value.isEnabled,
        'serviceAvailabilities': value.serviceAvailabilities === undefined ? undefined : ((value.serviceAvailabilities as Array<any>).map(BusinessServiceAvailabilityToJSON)),
        'defaultQuantity': value.defaultQuantity,
        'sortOrder': value.sortOrder,
        'customizations': value.customizations === undefined ? undefined : ((value.customizations as Array<any>).map(MenuCustomizationToJSON)),
        'caloriesBase': value.caloriesBase,
        'caloriesMax': value.caloriesMax,
        'oloId': value.oloId,
        'oloChainId': value.oloChainId,
        'chowlyId': value.chowlyId,
        'chowlyExternalId': value.chowlyExternalId,
        'itsaCheckmateId': value.itsaCheckmateId,
    };
}


