/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    EVMChain,
    EVMChainFromJSON,
    EVMChainFromJSONTyped,
    EVMChainToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a grouping of NftTrackers. Primarily used to combine multiple Nft Collections that span multiple contracts. It also is the primary space to hold the metadata to be displayed to the user.
 * @export
 * @interface NftGrouping
 */
export interface NftGrouping {
    /**
     * 
     * @type {string}
     * @memberof NftGrouping
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof NftGrouping
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof NftGrouping
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof NftGrouping
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof NftGrouping
     */
    sortPriority?: number;
    /**
     * 
     * @type {string}
     * @memberof NftGrouping
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof NftGrouping
     */
    discordUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof NftGrouping
     */
    twitterUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof NftGrouping
     */
    websiteUrl?: string;
    /**
     * 
     * @type {Asset}
     * @memberof NftGrouping
     */
    hero?: Asset;
    /**
     * 
     * @type {Asset}
     * @memberof NftGrouping
     */
    icon?: Asset;
    /**
     * 
     * @type {boolean}
     * @memberof NftGrouping
     */
    disabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NftGrouping
     */
    isGuildGrouping?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NftGrouping
     */
    numOfMembers?: number;
    /**
     * 
     * @type {number}
     * @memberof NftGrouping
     */
    rank?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof NftGrouping
     */
    categories?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof NftGrouping
     */
    totalPoints?: number;
    /**
     * 
     * @type {Array<EVMChain>}
     * @memberof NftGrouping
     */
    chains?: Array<EVMChain>;
}

export function NftGroupingFromJSON(json: any): NftGrouping {
    return NftGroupingFromJSONTyped(json, false);
}

export function NftGroupingFromJSONTyped(json: any, ignoreDiscriminator: boolean): NftGrouping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': json['name'],
        'sortPriority': !exists(json, 'sortPriority') ? undefined : json['sortPriority'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'discordUrl': !exists(json, 'discordUrl') ? undefined : json['discordUrl'],
        'twitterUrl': !exists(json, 'twitterUrl') ? undefined : json['twitterUrl'],
        'websiteUrl': !exists(json, 'websiteUrl') ? undefined : json['websiteUrl'],
        'hero': !exists(json, 'hero') ? undefined : AssetFromJSON(json['hero']),
        'icon': !exists(json, 'icon') ? undefined : AssetFromJSON(json['icon']),
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'isGuildGrouping': !exists(json, 'isGuildGrouping') ? undefined : json['isGuildGrouping'],
        'numOfMembers': !exists(json, 'numOfMembers') ? undefined : json['numOfMembers'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'totalPoints': !exists(json, 'totalPoints') ? undefined : json['totalPoints'],
        'chains': !exists(json, 'chains') ? undefined : ((json['chains'] as Array<any>).map(EVMChainFromJSON)),
    };
}

export function NftGroupingToJSON(value?: NftGrouping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'sortPriority': value.sortPriority,
        'description': value.description,
        'discordUrl': value.discordUrl,
        'twitterUrl': value.twitterUrl,
        'websiteUrl': value.websiteUrl,
        'hero': AssetToJSON(value.hero),
        'icon': AssetToJSON(value.icon),
        'disabled': value.disabled,
        'isGuildGrouping': value.isGuildGrouping,
        'numOfMembers': value.numOfMembers,
        'rank': value.rank,
        'categories': value.categories,
        'totalPoints': value.totalPoints,
        'chains': value.chains === undefined ? undefined : ((value.chains as Array<any>).map(EVMChainToJSON)),
    };
}


