/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    ChallengeAllOf,
    ChallengeAllOfFromJSON,
    ChallengeAllOfFromJSONTyped,
    ChallengeAllOfToJSON,
    ChallengeReward,
    ChallengeRewardFromJSON,
    ChallengeRewardFromJSONTyped,
    ChallengeRewardToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection contains challenges that can be completed by users
 * @export
 * @interface Challenge
 */
export interface Challenge {
    /**
     * 
     * @type {string}
     * @memberof Challenge
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Challenge
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Challenge
     */
    updatedAt: Date;
    /**
     * The title of the challenge
     * @type {string}
     * @memberof Challenge
     */
    name: string;
    /**
     * The description of the challenge
     * @type {string}
     * @memberof Challenge
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Challenge
     */
    backgroundImageLarge?: string;
    /**
     * 
     * @type {string}
     * @memberof Challenge
     */
    backgroundImageSmall?: string;
    /**
     * 
     * @type {string}
     * @memberof Challenge
     */
    brandLogo?: string;
    /**
     * Hex color code for the brand
     * @type {string}
     * @memberof Challenge
     */
    brandColor?: string;
    /**
     * 
     * @type {Array<ChallengeReward>}
     * @memberof Challenge
     */
    rewards: Array<ChallengeReward>;
    /**
     * UNIX timestamp for when the challenge starts
     * @type {number}
     * @memberof Challenge
     */
    start: number;
    /**
     * UNIX timestamp for when the challenge ends
     * @type {number}
     * @memberof Challenge
     */
    end: number;
    /**
     * 
     * @type {boolean}
     * @memberof Challenge
     */
    hasEnded?: boolean;
    /**
     * Whether the challenge is active
     * @type {boolean}
     * @memberof Challenge
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Challenge
     */
    instantRewardQuest?: string;
}

export function ChallengeFromJSON(json: any): Challenge {
    return ChallengeFromJSONTyped(json, false);
}

export function ChallengeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Challenge {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'name': json['name'],
        'description': json['description'],
        'backgroundImageLarge': !exists(json, 'backgroundImageLarge') ? undefined : json['backgroundImageLarge'],
        'backgroundImageSmall': !exists(json, 'backgroundImageSmall') ? undefined : json['backgroundImageSmall'],
        'brandLogo': !exists(json, 'brandLogo') ? undefined : json['brandLogo'],
        'brandColor': !exists(json, 'brandColor') ? undefined : json['brandColor'],
        'rewards': ((json['rewards'] as Array<any>).map(ChallengeRewardFromJSON)),
        'start': json['start'],
        'end': json['end'],
        'hasEnded': !exists(json, 'hasEnded') ? undefined : json['hasEnded'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'instantRewardQuest': !exists(json, 'instantRewardQuest') ? undefined : json['instantRewardQuest'],
    };
}

export function ChallengeToJSON(value?: Challenge | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'name': value.name,
        'description': value.description,
        'backgroundImageLarge': value.backgroundImageLarge,
        'backgroundImageSmall': value.backgroundImageSmall,
        'brandLogo': value.brandLogo,
        'brandColor': value.brandColor,
        'rewards': ((value.rewards as Array<any>).map(ChallengeRewardToJSON)),
        'start': value.start,
        'end': value.end,
        'hasEnded': value.hasEnded,
        'isActive': value.isActive,
        'instantRewardQuest': value.instantRewardQuest,
    };
}


