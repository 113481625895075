/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OWRewardType,
    OWRewardTypeFromJSON,
    OWRewardTypeFromJSONTyped,
    OWRewardTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChallengeReward
 */
export interface ChallengeReward {
    /**
     * 
     * @type {number}
     * @memberof ChallengeReward
     */
    amount?: number;
    /**
     * 
     * @type {OWRewardType}
     * @memberof ChallengeReward
     */
    rewardType: OWRewardType;
    /**
     * 
     * @type {string}
     * @memberof ChallengeReward
     */
    nftTrackerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeReward
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeReward
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeReward
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeReward
     */
    disclaimer?: string;
}

export function ChallengeRewardFromJSON(json: any): ChallengeReward {
    return ChallengeRewardFromJSONTyped(json, false);
}

export function ChallengeRewardFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChallengeReward {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'rewardType': OWRewardTypeFromJSON(json['rewardType']),
        'nftTrackerId': !exists(json, 'nftTrackerId') ? undefined : json['nftTrackerId'],
        'name': json['name'],
        'description': json['description'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'disclaimer': !exists(json, 'disclaimer') ? undefined : json['disclaimer'],
    };
}

export function ChallengeRewardToJSON(value?: ChallengeReward | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'rewardType': OWRewardTypeToJSON(value.rewardType),
        'nftTrackerId': value.nftTrackerId,
        'name': value.name,
        'description': value.description,
        'image': value.image,
        'disclaimer': value.disclaimer,
    };
}


