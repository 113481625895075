/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetGuildQuestsResponseAllOf,
    GetGuildQuestsResponseAllOfFromJSON,
    GetGuildQuestsResponseAllOfFromJSONTyped,
    GetGuildQuestsResponseAllOfToJSON,
    GuildQuest,
    GuildQuestFromJSON,
    GuildQuestFromJSONTyped,
    GuildQuestToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetGuildQuestsResponse
 */
export interface GetGuildQuestsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<GuildQuest>}
     * @memberof GetGuildQuestsResponse
     */
    quests: Array<GuildQuest>;
}

export function GetGuildQuestsResponseFromJSON(json: any): GetGuildQuestsResponse {
    return GetGuildQuestsResponseFromJSONTyped(json, false);
}

export function GetGuildQuestsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetGuildQuestsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'quests': ((json['quests'] as Array<any>).map(GuildQuestFromJSON)),
    };
}

export function GetGuildQuestsResponseToJSON(value?: GetGuildQuestsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'quests': ((value.quests as Array<any>).map(GuildQuestToJSON)),
    };
}


