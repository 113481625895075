/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChallengeResponseWithAcceptance,
    ChallengeResponseWithAcceptanceFromJSON,
    ChallengeResponseWithAcceptanceFromJSONTyped,
    ChallengeResponseWithAcceptanceToJSON,
    GetChallengesResponseAllOf,
    GetChallengesResponseAllOfFromJSON,
    GetChallengesResponseAllOfFromJSONTyped,
    GetChallengesResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetChallengesResponse
 */
export interface GetChallengesResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<ChallengeResponseWithAcceptance>}
     * @memberof GetChallengesResponse
     */
    challenges: Array<ChallengeResponseWithAcceptance>;
}

export function GetChallengesResponseFromJSON(json: any): GetChallengesResponse {
    return GetChallengesResponseFromJSONTyped(json, false);
}

export function GetChallengesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChallengesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'challenges': ((json['challenges'] as Array<any>).map(ChallengeResponseWithAcceptanceFromJSON)),
    };
}

export function GetChallengesResponseToJSON(value?: GetChallengesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'challenges': ((value.challenges as Array<any>).map(ChallengeResponseWithAcceptanceToJSON)),
    };
}


