import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import FrameButton from "@/components/buttons/FrameButton";
import {ButtonHTMLAttributes, useState} from "react";
import {BsArrowRight} from "react-icons/bs";
import {isDesktop} from "react-device-detect";
import {useNavigate} from "react-router-dom";
import useGetGuildOwnerships from "@/hooks/useGetGuildOwnerships";
import {getDateInEst} from "@/utils/getDateInEst";
import useGetCurrentGuildTopMembers from "@/hooks/useGetCurrentGuildTopMembers";
import GuildLeaderboardMyRankInfo from "@/components/goFrens/GuildLeaderboardMyRankInfo";
import GoFrensLeaderboardMyRankInfoSkeleton
    from "@/components/skeletons/GoFrensPage/GoFrensLeaderboardMyRankInfoSkeleton";
import GoFrensMyCommunityLeaderboardSkeleton
    from "@/components/skeletons/GoFrensPage/GoFrensMyCommunityLeaderboardSkeleton";
import GuildMembersLeaderboard from "@/pages/guilds/GuildMembersLeaderboard";
import GuildTopMembersLeaderboardModal from "@/pages/guilds/GuildTopMembersLeaderboardModal";

export default function GuildMembershipInfo() {
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const navigate = useNavigate();
    const now = getDateInEst();
    const dateParam = `${now.getMonth() + 1},${now.getFullYear()}`;
    const {data: topMembersData} = useGetCurrentGuildTopMembers(fullToken, `${dateParam},0`, true);
    const {data: guildsOwnership} = useGetGuildOwnerships(fullToken, true);
    const rankedUser = guildsOwnership?.membersAroundUser?.find(member => member.id === currentUser?.user?.id);
    const [showTopMembersModal, setShowTopMembersModal] = useState<boolean>(false);
    const topMembers = topMembersData?.members.filter(member => member.totalPoints > 0);

    function renderBoostYourRankButton() {
        return (
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color="purple"
                size="normal"
                rightIcon={BsArrowRight}
                className={`boost-your-rank ${isDesktop ? "leaderboard-small-btn" : ""}`}
                onClick={() => navigate("/go-vip-dashboard")}
            >
                Boost Your Rank
            </FrameButton>
        );
    }

    function toggleModal() {
        setShowTopMembersModal(!showTopMembersModal);
    }

    return (
        <div className="guild-members-leaderboard gofrens-my-community">
            <GuildTopMembersLeaderboardModal
                isOpen={showTopMembersModal}
                toggle={toggleModal}
                initialDateValue={dateParam}
            />
            <div className="gofrens-my-community-leaderboard">
                <div className="gofrens-my-community-leaderboard_top">
                    <div className="gofrens-my-community-leaderboard_top_header">
                        <div className="gofrens-my-community-leaderboard_top_header_user">
                            <div className="gofrens-my-community-leaderboard_top_header_user_image-container">
                                {currentUser?.user?.gravatar &&
                                    <img src={currentUser.user.gravatar} alt="User Profile"/>}
                            </div>
                            <h4>{currentUser?.user?.nickname || "GoVIP Player"}</h4>
                        </div>
                        {isDesktop && renderBoostYourRankButton()}
                    </div>

                    {guildsOwnership?.membersAroundUser
                        ? <>
                            <GuildLeaderboardMyRankInfo
                                rank={rankedUser?.rank}
                                pointsEarnedForCommunity={rankedUser?.totalPoints}
                            />

                            <GuildMembersLeaderboard
                                members={guildsOwnership.membersAroundUser.filter(member => member.totalPoints > 0)}
                                isAroundUserRanking={true}
                            />
                        </>
                        : <>
                            <GoFrensLeaderboardMyRankInfoSkeleton/>
                            <GoFrensMyCommunityLeaderboardSkeleton rows={3}/>
                        </>
                    }

                    {!isDesktop && renderBoostYourRankButton()}
                </div>

                <div className="gofrens_divider"/>

                <div className="gofrens-my-community-leaderboard_bottom">
                    <div className="gofrens-my-community-leaderboard_bottom_header">
                        <h3 className="guilds-page_section_header">Top Members</h3>
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="white-drop-shadow"
                            size="normal"
                            className="gofrens-top-leaderboard_top-inputs_button leaderboard-small-btn"
                            onClick={toggleModal}
                        >
                            View Top 50
                        </FrameButton>
                    </div>

                    <div className="gofrens-my-community-leaderboard_bottom_body">
                        {topMembers
                            ? <GuildMembersLeaderboard
                                members={topMembers.slice(0, 5)}
                                isCurrentAllTime={true}/>
                            : <GoFrensMyCommunityLeaderboardSkeleton rows={5}/>}
                    </div>
                </div>
            </div>
        </div>
    );
}