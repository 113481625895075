/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OWLogCategory,
    OWLogCategoryFromJSON,
    OWLogCategoryFromJSONTyped,
    OWLogCategoryToJSON,
    OWQuestReward,
    OWQuestRewardFromJSON,
    OWQuestRewardFromJSONTyped,
    OWQuestRewardToJSON,
    OWRewardMaxType,
    OWRewardMaxTypeFromJSON,
    OWRewardMaxTypeFromJSONTyped,
    OWRewardMaxTypeToJSON,
    OWRewardMilestone,
    OWRewardMilestoneFromJSON,
    OWRewardMilestoneFromJSONTyped,
    OWRewardMilestoneToJSON,
    QuestReset,
    QuestResetFromJSON,
    QuestResetFromJSONTyped,
    QuestResetToJSON,
} from './';

/**
 * 
 * @export
 * @interface OWQuestBody
 */
export interface OWQuestBody {
    /**
     * 
     * @type {string}
     * @memberof OWQuestBody
     */
    game?: string;
    /**
     * The title of the reward.
     * @type {string}
     * @memberof OWQuestBody
     */
    name: string;
    /**
     * The description of the reward.
     * @type {string}
     * @memberof OWQuestBody
     */
    description?: string;
    /**
     * If the quest is active (shows on FE and tracks in the BE) or not.
     * @type {boolean}
     * @memberof OWQuestBody
     */
    isActive: boolean;
    /**
     * The maximum amount of reward that can be claimed.
     * @type {number}
     * @memberof OWQuestBody
     */
    rewardMax?: number;
    /**
     * 
     * @type {OWRewardMaxType}
     * @memberof OWQuestBody
     */
    rewardMaxType?: OWRewardMaxType;
    /**
     * 
     * @type {OWLogCategory}
     * @memberof OWQuestBody
     */
    rewardCategory: OWLogCategory;
    /**
     * 
     * @type {string}
     * @memberof OWQuestBody
     */
    eventType: string;
    /**
     * The target amount of progress to reach to claim the reward.
     * @type {number}
     * @memberof OWQuestBody
     */
    target: number;
    /**
     * 
     * @type {QuestReset}
     * @memberof OWQuestBody
     */
    questReset?: QuestReset;
    /**
     * 
     * @type {Array<OWRewardMilestone>}
     * @memberof OWQuestBody
     */
    milestones?: Array<OWRewardMilestone>;
    /**
     * 
     * @type {Array<OWQuestReward>}
     * @memberof OWQuestBody
     */
    questRewards?: Array<OWQuestReward>;
}

export function OWQuestBodyFromJSON(json: any): OWQuestBody {
    return OWQuestBodyFromJSONTyped(json, false);
}

export function OWQuestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWQuestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'game': !exists(json, 'game') ? undefined : json['game'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isActive': json['isActive'],
        'rewardMax': !exists(json, 'rewardMax') ? undefined : json['rewardMax'],
        'rewardMaxType': !exists(json, 'rewardMaxType') ? undefined : OWRewardMaxTypeFromJSON(json['rewardMaxType']),
        'rewardCategory': OWLogCategoryFromJSON(json['rewardCategory']),
        'eventType': json['eventType'],
        'target': json['target'],
        'questReset': !exists(json, 'questReset') ? undefined : QuestResetFromJSON(json['questReset']),
        'milestones': !exists(json, 'milestones') ? undefined : ((json['milestones'] as Array<any>).map(OWRewardMilestoneFromJSON)),
        'questRewards': !exists(json, 'questRewards') ? undefined : ((json['questRewards'] as Array<any>).map(OWQuestRewardFromJSON)),
    };
}

export function OWQuestBodyToJSON(value?: OWQuestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'game': value.game,
        'name': value.name,
        'description': value.description,
        'isActive': value.isActive,
        'rewardMax': value.rewardMax,
        'rewardMaxType': OWRewardMaxTypeToJSON(value.rewardMaxType),
        'rewardCategory': OWLogCategoryToJSON(value.rewardCategory),
        'eventType': value.eventType,
        'target': value.target,
        'questReset': QuestResetToJSON(value.questReset),
        'milestones': value.milestones === undefined ? undefined : ((value.milestones as Array<any>).map(OWRewardMilestoneToJSON)),
        'questRewards': value.questRewards === undefined ? undefined : ((value.questRewards as Array<any>).map(OWQuestRewardToJSON)),
    };
}


