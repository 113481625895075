/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RewardResponseObject,
    RewardResponseObjectFromJSON,
    RewardResponseObjectFromJSONTyped,
    RewardResponseObjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClaimGuildQuestResponse
 */
export interface ClaimGuildQuestResponse {
    /**
     * 
     * @type {Array<RewardResponseObject>}
     * @memberof ClaimGuildQuestResponse
     */
    rewards: Array<RewardResponseObject>;
}

export function ClaimGuildQuestResponseFromJSON(json: any): ClaimGuildQuestResponse {
    return ClaimGuildQuestResponseFromJSONTyped(json, false);
}

export function ClaimGuildQuestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimGuildQuestResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rewards': ((json['rewards'] as Array<any>).map(RewardResponseObjectFromJSON)),
    };
}

export function ClaimGuildQuestResponseToJSON(value?: ClaimGuildQuestResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rewards': ((value.rewards as Array<any>).map(RewardResponseObjectToJSON)),
    };
}


